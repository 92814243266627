/* .Selltext-center{

} */
.SellPara {
    font-size: 31px;
    /* color: #FFD400; */
  }
  .sellParaTwo {
    width: 72%;
    text-align: center;
    font-size: 18px;
  }
  .floridaLineYelloww {
    height: 1px;
    width: 15%;
    margin-top: 25px;
    background-color: #ffd400;
  }
  .SellParaLast {
    font-size: 31px;
    margin-top: 140px;
    width: 60%;
    text-align: center;
    margin-bottom: 72px !important;
  }
  .SellSettingPrice {
    font-family: proxima-nova, sans-serif;
    background-color: #f3f3f3;
    /* height: 650px; */
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    padding-top: 48px;
    padding-bottom: 48px;
  }
  
  .ThirdPara {
    font-size: 31px !important;
    color: #ffd400 !important;
    margin-top: 65px;
  }
  
  .bold {
    font-weight: bold;
    color: #ffd400;
  }
  
  .companyReviews {
    margin-top: 35px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    /* gap: 20px; */
    /* flex-direction: column; */
    align-items: center;
  }
  
  .companyReviews img {
    width: 17%;
    object-fit: contain;
  }
  
  .Selltext-center {
    width: 80%;
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: 300;
    color: #4a4646;
    margin-top: 60px;
    margin-bottom: 0px;
    font-size: 1.38889rem;
    text-align: center;
    line-height: 1.5;
    font-size: 25px;
    opacity: 0.9;
  }
  
  .floridaFourthSelliconsMain {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 3%;
    align-items: baseline;
  }
  
  .floridaFourthSellIcons {
    width: 28%;
    gap: 5%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
    margin: 0px;
  }
  
  .text-center {
    font-size: 25px !important;
  }
  .floridaFourthSellIcons img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin-top: 70px;
    transition: transform 0.3s ease;
  
    /* transform: scale(1); */
  }
  
  .floridaFourthSellIcons img:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease;
  }
  
  .floridaFourthSellIcons h4 {
    font-size: 25px;
    margin-top: 20px;
    color: #4f4c4c;
    opacity: 1;
    font-weight: normal;
  }

  .floridaFourthSellIcons h5{
    font-size: 18px;
    color: black;
    font-weight: 400;
    /* width: 90%; */
  }
  .floridaFourthSellIcons p {
    font-size: 18px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 80px;
    line-height: 1.6;
    /* opacity: 0.9; */
  }


  
  @media screen and (max-width: 470px) {
    .companyReviews {
      margin-top: 35px;
      width: 100%;
      display: flex;
      flex-direction: column !important;
      justify-content: space-around;
      /* gap: 20px; */
      /* flex-direction: column; */
      align-items: center;
    }
  }
  @media screen and (max-width: 640px) {
    .SellPara {
      font-size: 24px !important;
      /* color: #FFD400; */
    }
  }
  
  @media screen and (max-width: 740px) {
    .SellParaLast {
      font-size: 24px;
      margin-top: 140px;
      width: 85%;
      text-align: center;
      margin-bottom: 72px !important;
    }
  }
  
  @media screen and (max-width: 740px) {
    .SellSettingPrice {
      background-color: #f3f3f3;
      /* height: 650px; */
      display: flex;
      /* justify-content: center; */
      flex-direction: column;
      align-items: center;
      padding-bottom: 40px !important;
    }
  
    .ThirdPara {
      font-size: 23px;
      color: #ffd400 !important;
      margin-top: 65px;
    }
  
    .bold {
      font-weight: bold;
      color: #ffd400 !important;
    }
  
    .companyReviews {
      margin-top: 35px;
      width: 90%;
      display: flex;
      /* flex-direction: column!important; */
      justify-content: space-around;
      /* gap: 20px; */
      /* flex-direction: column; */
      align-items: center;
    }
  
    .companyReviews img {
      width: 65%;
      object-fit: contain;
    }
  
    .SellSettingPrice h4 {
      font-size: 18px;
      width: 80%;
      font-family: proxima-nova, sans-serif;
      font-style: normal;
      font-weight: 300;
      color: #4a4646;
      margin-top: 70px;
      margin-bottom: 0px;
      /* font-size: 1.38889rem; */
      text-align: center;
      line-height: 1.5;
      opacity: 0.9;
    }
  
    .floridaFourthSelliconsMain {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 8%;
      width: 70%;
    }
  
    .floridaFourthSellIcons {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0px;
    }
  
    .floridaFourthSellIcons p {
      font-size: 18px;
      margin-bottom: 15px;
    }
  
    .floridaFourthSellIcons h4 {
      font-size: 18px;
      margin-top: 10px;
      color: #4f4c4c;
      margin-bottom: 3px !important;
    }
    .revCardsCont {
      display: flex;
      width: 100%;
      justify-content: space-around;
      flex-direction: column;
      align-items: center;
    }
  
    .revCards {
      flex-wrap: wrap;
      justify-content: space-around;
      width: 100%;
      display: flex;
      margin-top: 30px;
      /* margin: 0px 5%; */
      align-items: center;
    }
  
    .revCard {
      width: 85%;
      background-color: white;
      display: flex;
      /* justify-content: center; */
      flex-direction: column;
      align-items: center;
      padding: 2%;
      text-align: center;
      line-height: 1.6;
      /* margin-bottom: 20px; */
      height: fit-content;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    }
  
    .revCard h4 {
      margin-top: 0px;
    }
  
    .text-center {
      font-size: 18px !important;
    }
  }
  
  @media screen and (max-width: 750px) and (min-width: 740px) {
    .companyReviews img {
      width: 23% !important;
      object-fit: contain;
    }
  
    .SellSettingPrice {
      background-color: #f3f3f3;
      /* height: 650px; */
      display: flex;
      /* justify-content: center; */
      flex-direction: column;
      align-items: center;
      padding-bottom: 40px !important;
    }
  
    .ThirdPara {
      font-size: 23px;
      color: #ffd400 !important;
      margin-top: 65px;
    }
  
    .bold {
      font-weight: bold;
      color: #ffd400 !important;
    }
  
    .SellSettingPrice h4 {
      font-size: 18px;
      width: 80%;
      font-family: proxima-nova, sans-serif;
      font-style: normal;
      font-weight: 300;
      color: #4a4646;
      margin-top: 70px;
      margin-bottom: 0px;
      /* font-size: 1.38889rem; */
      text-align: center;
      line-height: 1.5;
      opacity: 0.9;
    }
  
    .floridaFourthSelliconsMain {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      gap: 8%;
      width: 100%;
      flex-wrap: wrap;
    }
  
    .floridaFourthSellIcons {
      width: 45%;
      display: flex;
      /* flex-direction: row; */
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0px;
    }
  
    .floridaFourthSellIcons p {
      font-size: 18px;
      margin-bottom: 15px;
    }
  
    .floridaFourthSellIcons h4 {
      font-size: 18px;
      margin-top: 10px;
      color: #4f4c4c;
      opacity: 1;
      font-weight: normal;
      margin-bottom: 3px !important;
    }


  }
  
  @media screen and (max-width: 1050px) and (min-width: 750px) {
    .companyReviews img {
      width: 23% !important;
      object-fit: contain;
    }
    .revCardsTwo img {
      width: 70px;
      height: 14px;
    }
      .SellSettingPrice {
        background-color: #f3f3f3;
        /* height: 650px; */
        display: flex;
        /* justify-content: center; */
        flex-direction: column;
        align-items: center;
        padding-bottom: 40px !important;
      }
    
      .ThirdPara {
        font-size: 23px;
        color: #FFD400 !important;
        margin-top: 65px;
      }
    
      .bold {
        font-weight: bold;
        color: #FFD400 !important;
      }
    
      .thirSellSettingPricedSec h4 {
        font-size: 18px;
        width: 80%;
        font-family: proxima-nova, sans-serif;
        font-style: normal;
        font-weight: 300;
        color: #4a4646;
        margin-top: 70px;
        margin-bottom: 0px;
        /* font-size: 1.38889rem; */
        text-align: center;
        line-height: 1.5;
        opacity: 0.9;
      }
    
      .floridaFourthSelliconsMain {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        gap: 8%;
        width: 100%;
        flex-wrap: wrap;
      }
    
      .floridaFourthSellIcons {
        width: 43%;
        display: flex;
        /* flex-direction: row; */
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0px;
      }
    
      .floridaFourthSellIcons p {
        font-size: 18px;
        margin-bottom: 15px;
      }
    
      .floridaFourthSellIcons h4 {
        font-size: 18px;
        margin-top: 10px;
        color: #4f4c4c;
        margin-bottom: 3px !important;
      }
 
    
      .text-center {
        font-size: 18px !important;
      }
    }
    
    .zoom-on-hover {
      /* Initial styling */
      width: 16%;
    
      /* Apply zoom effect on hover */
      transition: transform 0.3s ease; /* Add the same transition for consistency */
    }
    
    .zoom-on-hover:hover {
      transform: scale(1.1); /* Adjust the scale factor as desired */
    }