.thirdSec {
  background-color: #f3f3f3;
  display: flex;

  flex-direction: column;
  align-items: center;
}

.ThirdParaaaa {
  font-size: 31px !important;
  color: black !important;
  margin-top: 65px;
}

.bold {
  font-weight: bold;
  color: #FFD400;
}

.companyReviews {
  margin-top: 35px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  /* gap: 20px; */
  /* flex-direction: column; */
  align-items: center;
}

.companyReviews img {
  width: 17%;
  object-fit: contain;
}

.thirdSec h4 {
  width: 80%;
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 300;
  color: #4a4646;
  margin-top: 100px;
  margin-bottom: 0px;
  font-size: 1.38889rem;
  text-align: center;
  line-height: 1.5;
  opacity: 0.9;
}

.iconsMain {
  display: flex;
  justify-content: space-around;
  gap: 10%;
}

.Icons {
  width: 29%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0px;
}
.text-center {
  font-size: 25px !important;
}
.Icons img {
  margin-top: 70px;
  transition: transform 0.3s ease;
}

.Icons img:hover {
  transform: scale(1.2);
}

.Icons h4 {
  font-size: 25px;
  margin-top: 10px;
  color: #4f4c4c;
  opacity: 0.7;
}
.Icons p {
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 80px;
  line-height: 1.6;
  /* opacity: 0.9; */
}
.revCardsCont {
  display: flex;
  width: 100%;
  justify-content: space-around;
  justify-content: center;
  gap: 2.4%;
}
.revCard {
  width: 90%;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 5%;
  text-align: center;
  line-height: 1.6;
  margin-bottom: -10px;
  height: fit-content;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.revCard h4 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 0px;
}
.revCard p {
  font-size: 18px;
}
.revCards {
  flex-wrap: wrap;
  /* justify-content: space-around; */
  width: 29%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
  /* margin: 0px 5%; */
}
.revCard img {
  width: 25%;
  margin-top: 10px;
  object-fit: contain;
}

@media screen and (max-width: 470px) {
  .companyReviews {
    margin-top: 35px;
    width: 100%;
    display: flex;
    flex-direction: column !important;
    justify-content: space-around;
    /* gap: 20px; */
    /* flex-direction: column; */
    align-items: center;
  }
}

@media screen and (max-width: 740px) {
  .thirdSec {
    background-color: #f3f3f3;
    /* height: 650px; */
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px !important;
  }

  .ThirdParaaaa {
    font-size: 23px;

    margin-top: 65px;
  }

  .bold {
    font-weight: bold;
    color: #FFD400 !important;
  }

  .companyReviews {
    margin-top: 35px;
    width: 90%;
    display: flex;
    /* flex-direction: column!important; */
    justify-content: space-around;
    /* gap: 20px; */
    /* flex-direction: column; */
    align-items: center;
  }

  .companyReviews img {
    width: 53%;
    object-fit: contain;
  }

  .thirdSec h4 {
    font-size: 18px;
    width: 80%;
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: 300;
    color: #4a4646;
    margin-top: 70px;
    margin-bottom: 0px;
    /* font-size: 1.38889rem; */
    text-align: center;
    line-height: 1.5;
    opacity: 0.9;
  }

  .iconsMain {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 8%;
    width: 70%;
  }

  .Icons {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0px;
  }

  .Icons p {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .Icons h4 {
    font-size: 18px;
    margin-top: 10px;
    color: #4f4c4c;
    margin-bottom: 3px !important;
  }
  .revCardsCont {
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
  }

  .revCards {
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    display: flex;
    margin-top: 30px;

    align-items: center;
  }

  .revCard {
    width: 85%;
    background-color: white;
    display: flex;

    flex-direction: column;
    align-items: center;
    padding: 2%;
    text-align: center;
    line-height: 1.6;

    height: fit-content;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  }

  .revCard h4 {
    margin-top: 0px;
  }

  .text-center {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 530px) and (min-width: 470px) {
}

@media screen and (max-width: 750px) and (min-width: 740px) {
  .companyReviews img {
    width: 23% !important;
    object-fit: contain;
  }

  .thirdSec {
    background-color: #f3f3f3;

    display: flex;

    flex-direction: column;
    align-items: center;
    padding-bottom: 40px !important;
  }

  .ThirdParaaaa {
    font-size: 23px;
    margin-top: 65px;
  }

  .bold {
    font-weight: bold;
    color: #FFD400 !important;
  }

  .thirdSec h4 {
    font-size: 18px;
    width: 80%;
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: 300;
    color: #4a4646;
    margin-top: 70px;
    margin-bottom: 0px;

    text-align: center;
    line-height: 1.5;
    opacity: 0.9;
  }

  .iconsMain {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 8%;
    width: 100%;
    flex-wrap: wrap;
  }

  .Icons {
    width: 45%;
    display: flex;

    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0px;
  }

  .Icons p {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .Icons h4 {
    font-size: 18px;
    margin-top: 10px;
    color: #4f4c4c;
    margin-bottom: 3px !important;
  }
  .revCardsCont {
    display: flex;
    width: 100%;
    justify-content: space-around;
    justify-content: center;
    gap: 2.4%;
  }
  .revCard {
    width: 90%;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 5%;
    text-align: center;
    line-height: 1.6;
    margin-bottom: -10px;
    height: fit-content;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  }

  .revCardsContTwo {
    display: flex;
    width: 100%;
    justify-content: space-around;
    justify-content: center;
    gap: 2.4%;
  }
  .revCardsTwo {
    flex-wrap: wrap;
 
    width: 46%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;
  }
  .revCardTwo {
    width: 90%;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 5%;
    text-align: center;
    line-height: 1.6;
    margin-bottom: -10px;
    height: fit-content;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  }
  .revCardTwo img{
    width: 70px; height: 14px;
  }

  .revCard h4 {
    margin-top: 0px;
  }

  .text-center {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 1050px) and (min-width: 750px) {
  .companyReviews img {
    width: 23% !important;
    object-fit: contain;
  }
.revCardsTwo img{
  width: 70px; height: 14px;
}
  .thirdSec {
    background-color: #f3f3f3;

    display: flex;

    flex-direction: column;
    align-items: center;
    padding-bottom: 40px !important;
  }

  .ThirdParaaaa {
    font-size: 23px;
    color: black !important;
    margin-top: 65px;
  }

  .bold {
    font-weight: bold;
    color: #FFD400 !important;
  }

  .thirdSec h4 {
    font-size: 18px;
    width: 80%;
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: 300;
    color: #4a4646;
    margin-top: 70px;
    margin-bottom: 0px;

    text-align: center;
    line-height: 1.5;
    opacity: 0.9;
  }

  .iconsMain {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 8%;
    width: 100%;
    flex-wrap: wrap;
  }

  .Icons {
    width: 43%;
    display: flex;
  
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0px;
  }

  .Icons p {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .Icons h4 {
    font-size: 18px;
    margin-top: 10px;
    color: #4f4c4c;
    margin-bottom: 3px !important;
  }
  .revCardsCont {
    display: flex;
    width: 100%;
    justify-content: space-around;
    justify-content: center;
    gap: 2.4%;
  }
  .revCard {
    width: 90%;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 5%;
    text-align: center;
    line-height: 1.6;
    margin-bottom: -10px;
    height: fit-content;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  }

  .revCardsContTwo {
    display: flex;
    width: 100%;
    justify-content: space-around;
    justify-content: center;
    gap: 2.4%;
  }
  .revCardsTwo {
    flex-wrap: wrap;

    width: 46%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;

  }
  .revCardTwo {
    width: 90%;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 5%;
    text-align: center;
    line-height: 1.6;
    margin-bottom: -10px;
    height: fit-content;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  }

  .revCard h4 {
    margin-top: 0px;
  }

  .text-center {
    font-size: 18px !important;
  }
}
