.progressBar {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 7px;
  padding: 1px;
  overflow: hidden;
}
.progressBarChange {
  height: 20px;
  width: 100%; /* Set the initial width to 100% to show full progress */
  border-radius: 3px;
  background-color: #ffd400;
  background-image: linear-gradient(
    -45deg,
    #ffb800 25%,
    transparent 0,
    transparent 50%,
    #ffb800 50%,
    #ffb800 75%,
    transparent 0,
    transparent
  );
  background-size: 26px 26px;
  animation: progress-stripes 6s linear infinite;
}

@keyframes progress-stripes {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
} /*# sourceMappingURL=progressBar.css.map */
