.rdtPicker {
  position: relative;
}
.rdt .rdtOpen {
  background-color: aqua;
}
.rdt .rdtOpen .form-control {
  display: none;
}
.date-picker .rdtPicker {
  /* background-color: aquamarine; */
  display: block;
  position: relative;
  width: 50%;
  background-color: white;
}

.date-picker .rdtPicker td.rdtActive {
  background-color: #000000;
  color: white;
}
.date-picker .rdtPicker td.rdtActive:hover {
  background-color: #464168;
}

/* } */
.date-picker input {
  display: none !important;
}
.slots {
  /* margin: 10px ; */
  outline: none;
  border: 1px solid #000000;
  background-color: white;
  width: 32%;
  padding: 10px;
  border-radius: 5px;
}
.slots:hover {
  background-color: #000000;
  color: white;
}
.activer {
  background-color: #000000 !important;
  color: white !important;
}

@media screen and (max-width: 1270px) { 
  .date-picker .rdtPicker {
    /* background-color: aquamarine; */
    display: block;
    position: relative;
    width: 97%;
    background-color: white;
  }
  .input-field{

  }
 }

