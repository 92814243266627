.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 7;
}
.whiteOverlay {
  transition: background-color ease 9s;
  position: fixed;
  top: 0; /* No need for quotes */
  height: 100vh; /* No need for quotes */
  width: 100%; /* No need for quotes */
  background-color: rgba(255, 255, 255, 0.3); /* No quotes and using rgba */
  z-index: 2;
}

.menu-container {
  position: relative;
}

#menu-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.linkOne a {
  width: 100%;
}

.menu.open #menu-button {
  right: auto;
  left: 0;
}

.headerBtn {
  background-color: #ffd400;
  height: 80px;
  width: 80px;
  border: none;
  margin-left: 12px;
  cursor: pointer;
 
}

.number {
  font-size: 21px;
  font-weight: 500;
  color: white;
}

.headerLogo {
  position: static;
  width: 100%;
  object-fit: contain;
}

.headerBtn img {
  width: 80%;
}

.headerRight {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  right: 0;
}

.menu {
  width: 35%;
  height: 100%;
  background-color: #333;
  position: fixed;
  z-index: 10000000;
  top: 0;
  right: calc(-35% - 30px);
  padding: 30px 0px 0px 30px;
  transition: right 0.6s ease;
}

.menu.open {
  right: 0;
  padding: 30px 0px 0px 30px
}

.menu h3 {
  font-size: 27px;
  font-weight: 450;
  margin-top: 15px;
  margin-bottom: 10px;
  cursor: pointer;
}

.contact {
  cursor: pointer;
}

.menu h3:hover {
  color: #ffd400;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* .menuu{

}

.imageDiv{
    background-color: #231F1F;

}
.imageDiv img{
    width: 50%;
    object-fit: contain;
} */
.guaranteedLink {
  text-decoration: none;
}
.guaranteedLinkOpened h3{
  color: #ffd400 !important;
  /* text-decoration: none; */
}
.guaranteedLinkOpened{
  text-decoration: none;
}
.toGuaranteed {
  text-decoration: none;
  color: white;
}
.toGuaranteedCont{
  margin-top: 36px !important;
  text-decoration: none;
  color: white;
}
.a{
  text-decoration: none !important;
  color: white;
}

.toGuaranteed:hover {
  color: #ffd400;
  transition: background-color 0.3s ease, color 0.3s ease;
}

@media screen and (max-width: 1924px) and (min-width: 1023px) {
  .linkOne {
    width: 196px;
    height: 140px;
  }
  .whiteOverlay {
    transition: background-color ease 9s;
    position: fixed;
    top: 0; /* No need for quotes */
    height: 100vh; /* No need for quotes */
    width: 100%; /* No need for quotes */
    background-color: rgba(255, 255, 255, 0.3); /* No quotes and using rgba */
    z-index: 2;
  }
}

@media screen and (max-width: 1023px) {
  .linkOne {
    width: 113.98px;
    height: 79.98px;
  }
  .whiteOverlay {
    transition: background-color ease 9s;
    position: fixed;
    top: 0; /* No need for quotes */
    height: 100vh; /* No need for quotes */
    width: 100%; /* No need for quotes */
    background-color: rgba(255, 255, 255, 0.3); /* No quotes and using rgba */
    z-index: 2;
  }
}

@media screen and (max-width: 470px) {
  .linkOne {
    width: 113.98px;
    height: 79.98px;
  }

  /* .menu{
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0px;
        right: 0px;
    } */
  .header {
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 2;
    align-items: baseline;
  }

  .menu {
    width: 60%;
    height: 100%;
    background-color: #333;
    position: fixed;
    z-index: 10000000;
    /* top: 0; */
    /* right: -60%; */
    transition: right 0.6s ease;
    right: calc(-60% - 30px);
    padding: 30px 0px 0px 30px;
  }

  .menu h3 {
    font-size: 22px;
    font-weight: 450;
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .contact {
    /* padding-top: 25px; */
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
  }

  .headerBtn {
    background-color: #ffd400;
    height: 80px;
    width: 80px;
    border: none;
    margin-left: 12px;
  }

  .number {
    font-size: 21px;
    font-weight: 500;
    color: white;
  }

  .headerLogo {
    position: static;
    width: 100%;
    height: 75px;
    object-fit: contain;
  }

  .headerBtn img {
    width: 80%;
  }

  .headerRight {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;

    position: absolute;
    top: 0;
    right: 0;
  }
}

@media screen and (max-width: 375px) {
  .header {
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 2;
    align-items: baseline;
  }

  .headerLogo {
    position: static;
    width: 100%;
    height: 75px;
    object-fit: contain;
  }
}

@media screen and (max-width: 335px) {
  .headerLogo {
    position: static;
    width: 100%;
    height: 75px;
    object-fit: contain;
  }
}

@media screen and (max-width: 650px) and (min-width: 470px) {
  .menu {
    width: 60%;
    height: 100%;
    background-color: #333;
    position: fixed;
    z-index: 10000000;
    /* top: 0; */
    /* right: -60%; */
    transition: right 0.6s ease;
    right: calc(-60% - 30px);
    padding: 30px 0px 0px 30px;
  }
  

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 2;
  }
}

@media screen and (max-width: 840px) and (min-width: 651px) {
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 2;
  }
  .menu{
    width: 45%;
    height: 100%;
    background-color: #333;
    position: fixed;
    z-index: 10000000;
    /* top: 0; */
    /* right: -45%; */
    transition: right 0.6s ease;
    right: calc(-45% - 30px);
    padding: 30px 0px 0px 30px;
  }
}

@media screen and (max-width: 1000px) and (min-width: 840px) {
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 2;
  }
}
