.form-modal {
  display: flex;
  flex-direction: column;
  position: relative;
  padding:25px 40px;
  /* flex: 0 0 66.66667%; */
  max-width: 40%;
  margin: 168px auto 144px auto;
  background-color: white;
  
  font-family: proxima-nova, sans-serif;

}
.form-modal h2 {
  font-size: 28px;
  line-height: 34px;
  font-weight: 300;
  text-align: left;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.form-modal p span {
  margin: 0px 2px;
}
.importent {
  color: #231f1f !important;
  font-size: 13px;
  display: contents;
}
.select-arrows {
  position: absolute;
  right: 10px;
  top: 48px;
  color: #ffd400;
  font-size: 16px;
}
.input-field {
  position: relative;
}
.starter {
  display: flex;
  justify-content: space-between;
  color: #666666;
  cursor: pointer;
  font-size: 12px;
}

@media (max-width: 4023px) {
  .form-modal {
    display: flex;
    flex-direction: column;
    padding: 16px;
    flex: 0 0 90%;
    max-width: 45%;
    margin: 175px auto 100px auto;
    background-color: white;
    
    font-family: proxima-nova, sans-serif;

  }
}

@media (max-width: 1023px) {
  .form-modal {
    display: flex;
    flex-direction: column;
    padding: 16px;
    flex: 0 0 90%;
    max-width: 62%;
    margin: 175px auto 100px auto;
    background-color: white;
    
    font-family: proxima-nova, sans-serif;

  }
}

@media (max-width: 650px) {
  /* Add your styles here */
  .form-modal {
    display: flex;
    flex-direction: column;
    padding: 16px;
    flex: 0 0 90%;
    max-width: 85%;
    margin: 175px auto 100px auto;
    background-color: white;
    
    font-family: proxima-nova, sans-serif;

  }
}
@media (max-width: 450px) {
  /* Add your styles here */
  .form-modal {
    display: flex;
    flex-direction: column;
    padding: 16px;
    flex: 0 0 90%;
    max-width: 85%;
    margin: 175px auto 100px auto;
    background-color: white;
    
    font-family: proxima-nova, sans-serif;

  }
}
option{
  font-size: 15px !important;
}