.selectArrowsInfo {
  position: absolute;
  right: 11px;
  top: 7px;
  font-size: 22px;

  font-weight: bolder;
  color: #ffd400;
  z-index: 22;
}

.inputtt select:focus {
  /* color: black; */
  border: 1px solid #ffd400;
}

.inputtt:focus {
  /* color: black; */
  border: 1px solid #ffd400;
}
.inputtt {
  position: relative;
  width: 92.5%;
  background-color: #000000;
  border: 1px solid white;
  outline: none;
  height: 37px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border-radius: none !important;
  opacity: 0.8;
  margin-top: 0.5px;
}

.info {
  background-color: #000000;
  /* height: 500px; */
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  color: white;
  /* padding-top: 15px; */
  padding: 15px 20px 15px 30px;
}
.logoDiv {
  width: 13%;
  margin-top: 15px;
  margin-left: -25px;
}
.logoDiv img {
  width: 165px;
  height: 116px;
}
.SMarket {
  width: 25%;
}
.sMarketH1 {
  font-size: 31px !important;
  font-weight: 400;
}
.innerSMarket {
  display: flex;
  gap: 20%;
}

.innerSMarket p {
  width: 33%;
  font-weight: 500;
  margin: 9px 0px;
  cursor: pointer;
}
.innerSMarket p:hover {
  color: #ffd400;
}

.gOffer {
  width: 28%;
  font-weight: 500;
  margin-top: 10px;
}
.gOffer p {
  cursor: pointer;
}
.gOffer p:hover {
  color: #ffd400;
}

.line {
  background-color: white;
  height: 1px;
  margin-top: 9px;
  margin-bottom: 17px;
  opacity: 0.4;
  width: 107%;
}
.lineNone {
  display: none;
}

.line2 {
  width: 75%;
  background-color: white;
  height: 1px;
  margin-top: 9px;
  margin-bottom: 17px;
  opacity: 0.4;
}
.lineSpace {
  height: 25px;
}
.sConnected {
  width: 30%;
  display: flex;
  flex-direction: column;
}
.formm {
  background-color: #000000;
  border: 1px solid white;
  outline: none;
  height: 35px;
  width: 90%;
  font-weight: bold;
  font-size: 16px;
  color: #c1c1c1;
}

.formMain input {
  padding-left: 2%;
}
.formm:focus {
  border: 1px solid #ffd400;
  transition: background-color 0.3s ease;
}

.select:focus {
  border: 1px solid #ffd400;
  /* border: 1px solid #FFD400; */
  transition: background-color 0.3s ease;
}

.select {
  background-color: #000000;
  /* border: 1px solid white; */
  outline: none;
  height: 37px;
  width: 100%;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border-radius: none !important;
  opacity: 0.8;
  appearance: none;
  padding-left: 2%;
  z-index: 44;

  /* 
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none; */
  /* background-image: url('path/to/custom_arrow.png'); Replace with your custom arrow image */
  /* background-size: 12px; Adjust the size of the image as needed */
  /* background-position: right center;
    background-repeat: no-repeat; */
  /* padding-right: 20px; Add padding to prevent text from overlapping the arrow */
}
select.select {
  font-family: proxima-nova, sans-serif;
}
/* To remove the arrow of select element in IE */
.select::-ms-expand {
  /* display: none; */
}

.stateRed {
  font-size: 18px !important;
  color: #ffd400;
  margin: 6px 0px;
  cursor: pointer;
  font-weight: 600;
}
.stateRed a {
  text-decoration: none;
  font-size: 18px !important;
  color: #ffd400;
  margin: 6px 0px;
  cursor: pointer;
  font-weight: 600;
}

.formMain {
  display: flex;
  flex-direction: column;
  gap: 17px;
}
.formMain input {
}

::placeholder {
  color: #c1c1c1;
  font-weight: bold;
  /* padding-left: 5px; */
  font-family: proxima-nova, sans-serif;
  font-weight: bold;
  font-size: 16px;
}

.sConnected button {
  background-color: #000000;
  border: 1px solid white;
  color: white;
  height: 35px;
  font-size: 15px;
  width: 93%;
  margin-top: 18px;
  font-style: italic;
  font-weight: bold;
  font-family: "EB Garamond", sans-serif !important;
}

.sConnected button:hover {
  background-color: #ffd400;
  color: black;
  transition: background-color 0.3s ease;
}

.sConnected p {
  margin: 0;
  font-style: italic;
}

.footerLine {
  color: white;
  background-color: #000000;
  /* height: 5px; */
  padding: 0px 10px 30px 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  text-align: left;
}

.footerLine p {
  display: flex;
  width: 95%;
  color: white;
  opacity: 0.3;
  font-size: 13px;
  text-align: left;
  margin-top: 20px;
}

.linee {
  width: 95%;
  background-color: white;
  height: 1px;
  opacity: 0.2;
}
.sConnected h4 {
  margin: 0px;
  margin-top: 30px;
  font-size: 22px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.sConnected h6 {
  margin: 0px;
  margin-top: 7px;
  opacity: 0.3;
  font-size: 15px;
  font-weight: 400;
}
.sConnected h4:hover {
  color: #ffd400;
  /* transition: color 0.3s ease; */
}
.Paras {
  font-size: 13.5px !important;
  display: flex;
  gap: 2%;
  margin: 4px 0px 0px 5px;
}

.Paras p:hover {
  color: #ffd400;
}
.Paras p {
  font-weight: 600 !important;
  color: white;
  opacity: 1;
  cursor: pointer;
}

@media screen and (max-width: 850px) and (min-width: 655px) {
  .logoDiv {
    width: 20%;
    margin-left: -35px;
    margin-top: 15px;
  }
}

@media screen and (max-width: 650px) {
  .selectArrowsInfo {
    position: absolute;
    right: 11px;
    top: 10px;
    font-size: 22px;

    font-weight: bolder;
    color: #ffd400;
    z-index: 22;
  }
  .inputtt {
    position: relative;
    width: 99.4%;
    background-color: #000000;
    border: 1px solid white;
    outline: none;
    height: 35px;
    color: white;
    font-weight: bold;
    font-size: 16px;
    border-radius: none !important;
    opacity: 0.8;
  }

  .info {
    background-color: #000000;
    /* height: 500px; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* align-items: center; */
    color: white;
    /* padding-top: 15px; */
    padding: 15px 20px 15px 20px;
  }
  .logoDiv {
    width: 50%;
    margin-left: -33px;
    margin-top: 15px;
  }

  .SMarket {
    width: 25%;
  }
  .sMarketH1 {
    font-size: 24px !important;
    font-weight: 350;
    font-size: 23px;
  }
  .innerSMarket {
    display: flex;
    gap: 20%;
  }

  .innerSMarket p {
    width: 33%;
    font-weight: 500;
    margin: 9px 0px;
    cursor: pointer;
  }
  .innerSMarket p:hover {
    color: #ffd400;
  }

  .gOffer {
    width: 100%;
    font-weight: 500;
    margin-top: 10px;
  }
  .gOffer p {
    cursor: pointer;
  }
  .gOffer p:hover {
    color: #ffd400;
  }

  .line {
    background-color: white;
    height: 1px;
    margin-top: 9px;
    margin-bottom: 17px;
    opacity: 0.4;
    width: 100%;
  }

  .line2 {
    width: 100%;
    background-color: white;
    height: 1px;
    margin-top: 9px;
    margin-bottom: 17px;
    opacity: 0.4;
  }
  .lineSpace {
    display: none;
    height: 25px;
  }
  .lineNone {
    display: flex;
    background-color: white;
    height: 1px;
    margin-top: 9px;
    margin-bottom: 17px;
    opacity: 0.4;
    width: 100%;
  }
  .sConnected {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .sConnected p {
    font-size: 13px;
    font-weight: 600;
    margin-top: 2px !important;
    margin-left: 8px !important;
  }
  .formm {
    background-color: #000000;
    border: 1px solid white;
    outline: none;
    height: 35px;
    width: 98%;
    color: white;
  }

  .formMain {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  ::placeholder {
    color: #c1c1c1;
    font-weight: bold;
    /* padding-left: 5px; */
    font-size: 15px;
  }

  .sConnected button {
    background-color: #000000;
    border: 1px solid white;
    color: white;
    height: 38px;
    font-size: 15px;
    width: 100%;
    margin-top: 18px;
    font-style: italic;
    font-weight: bold;
  }

  .sConnected p {
    margin: 0;
  }

  .footerLine {
    color: white;
    background-color: #000000;
    /* height: 5px; */
    padding: 0px 10px 30px 10px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: left;
  }

  .footerLine p {
    display: flex;
    width: 75%;
    color: white;
    opacity: 0.3;
    font-size: 13px;
    text-align: left;
    margin-top: 20px;
    justify-self: baseline;
    align-self: baseline;
    margin-left: 3%;
  }

  .linee {
    width: 95%;
    background-color: white;
    height: 1px;
    opacity: 0.2;
  }
  .select {
    background-color: #000000;
    border: 1px solid white;
    outline: none;
    height: 35px;
    width: 100.4%;
    color: white;
    font-weight: bold;
    font-size: 16px;
  }
  .sConnected h4 {
    margin: 0px;
    margin-top: 30px;
    font-size: 22px;
    font-weight: 500;
    cursor: pointer;
  }
  .sConnected h6 {
    margin: 0px;
    margin-top: 7px;
    opacity: 0.3;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
  }
  .sConnected h4:hover {
    color: #ffd400;
  }
  .termsPara {
    line-height: 1.8;
    opacity: 0.9;
  }

  .Paras {
    font-size: 13.5px !important;
    display: flex;
    gap: 0px;
    margin: 0px;
  }

  .Paras p:hover {
    color: #ffd400;
  }

  .Paras p {
    font-weight: 500 !important;
    color: white;
    opacity: 1;
  }
}

@media screen and (max-width: 470px) {
  .select {
    background-color: #000000;
    border: 1px solid white;
    outline: none;
    height: 43px;
    width: 100%;
    color: white;
    font-weight: bold;
    font-size: 16px;
    border-radius: 4px;
  }
  .inputtt {
    position: relative;
    width: 101.7%;
    background-color: #000000;
    border: 1px solid white;
    outline: none;
    height: 43px;
    color: white;
    font-weight: bold;
    font-size: 16px;
    border-radius: none !important;
    opacity: 0.8;
    margin-top: 0.5px;
    border-radius: 4px;
  }
  .sConnected button {
    background-color: #000000;
    border: 1px solid white;
    color: white;
    height: 42px;
    font-size: 15px;
    width: 102%;
    margin-top: 18px;
    font-style: italic;
    font-weight: bold;
    border-radius: 4px;
  }
  .formm {
    background-color: #000000;
    border: 1px solid white;
    outline: none;
    height: 35px;
    width: 97.7%;
    color: white;
  }
}
