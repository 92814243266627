.AboutStoryMain{
    width: 100%;
    background-color: #F3F3F3;
    display: flex;
    align-items: center;
    padding-top: 72px;
}

.aboutStoryMainBlack{
    width: 100%;
}

.aboutStoryMainWhite{
    width: 100%;
}

.ourSuccess{
    width: 12%;
    background-color: #666666;
    padding: 18px 36px;
    font-size: 31px;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ourSuccess img{
    width: 35px;
    object-fit: contain;

}
.ourSuccess p{
    margin-top: 5px;
    margin-bottom: 5px;
}
@media only  screen and (max-width: 1023px) {
    .AboutStoryMain{
        flex-direction: column;
        width: 100%;
        background-color: #F3F3F3;
        display: flex;
        align-items: center;
        padding-top: 72px;
    }
    .ourSuccess{
        width: 100%;
        background-color: #666666;
        padding: 18px 0px;
        font-size: 31px;
        color: white;
        text-align: center;
    }
}