.aboutProud{
    background-image: url("../images/AboutUs_fourth.jpg");
    /* background-color: rgba(0, 0, 0, 0.5);  */
    
    background-position: center center !important;
    background-repeat: no-repeat; 
    font-family: proxima-nova, sans-serif;
    background-size: cover !important;
    /* height: 800px; */
    padding-top: 54px;
    padding-bottom: 82px;
    padding: 30px 20px 80px 20px;
    margin-top: 54px;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
}

.aboutProud p{
font-size: 31px;
color: black;
font-weight: bold;
margin-bottom: 75px;
}
.proudIconsMain{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    max-width: 1200px;
}
.iconDiv{
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.iconDiv img{
width: 45px;
height: 45px;
object-fit: contain;
}

.iconDiv p{
    font-size: 18px;
    color: #231f1f;
    font-family: icomoon !important;
    font-style: normal;
    font-weight: 100;
    width: 80%;
}
.proudIconPara{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 35px;

}
.proudIconPara p{
    line-height:1.6;
}

.iconDiv img:hover{
    transform: scale(1.2);
    transition: transform ease 200ms;
    }

    @media only  screen and (max-width: 630px) {
        .proudIconsMain {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;
            gap: 40px;
        }
        .iconDiv p {
            width: 87%;
        }
        .aboutProud {

            /* height: 800px; */
            padding-top: 54px;
            padding-bottom: 23px;
            margin-top: 54px;
            display: flex;
            /* justify-content: center; */
            flex-direction: column;
            align-items: center;
        }
    } 