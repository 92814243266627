/* If using CSS */
@font-face {
  font-family: "proxima-nova";
  src: url("../../src/fonts/proxima-nova.woff2") format("woff2");
}
@font-face {
  font-family: "EB Garamond";
  src: url("../fonts/EBGaramond-Italic-VariableFont_wght.ttf")
    format("truetype");
  font-style: italic;
  font-weight: 300; /* You may adjust the font-weight based on the available weights of the variable font */
}

.input-field {
  position: relative;
}
ul {
  color: black;
  color: black;
  position: absolute;
  top: 29px !important;
  background: white;
  width: calc(100% - -15px);
  width: 100%;
  padding: 0px;
  list-style: none;
}
ul li {
  display: flex;
  align-items: center;
  font-size: 13px !important;
  padding: 5px 10px;
  cursor: pointer;
}
ul li span {
  color: #cacaca;
  margin: 0px 10px 0px 0px;
}

.banner {
  background: linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)),
  url("../images/CMA-Background.png");
  background-position: center center !important;
  background-repeat: no-repeat;
  width: 100%;
  /* height: 600px; */
  padding: 155px 0px;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  font-family: proxima-nova, sans-serif;
  position: relative;
  z-index: 1;
  background-size: cover !important;

}
.banner::before {
  background-color: rgba(0, 0, 0, 1);
}
.bannerInner {
  /* margin-top: 110px; */
  width: 60%;
  z-index: 2;
  /* filter: brightness(100%); */
}

.bannerInner h1 {
  /* width: 80%; */
  font-size: 41px;
  font-weight: bolder;
}

.bannerInner h2 {
  font-size: 25.2px;
  font-weight: 500;
  font-family: proxima-nova, sans-serif;
}
.inputt {
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: proxima-nova, sans-serif;
}

.inputt input {
  width: 47%;
  height: 43px;
  outline: none;
  border: none;
  font-size: 18px;
  padding-left: 1%;
}

.inputt input::placeholder {
  font-weight: 400;
  
}
.inputt input {
  padding-left: 3%;
  font-size: 18px;
}

.inputt button {
  width: 136.83px;
  height: 45px;
  border: none;
  background-color: #ffd400;
  color: black;
  font-weight: bold;
  font-size: 18px;
  border: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent !important; 
}
.inputt a {
  width: 136.83px;
}

@media screen and (max-width: 450px) {
  .banner {
    /* margin-top: -150px; */
    background: linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)),
    url("../images/CMA-Background.png");
    background-position: center center !important;
    background-repeat: no-repeat;
    width: 100%;
    /* background-size: 100%; */
    /* height: 700px !important; */
    padding: 50px 0px;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    background-size: cover;
  }

  .bannerInner {
    margin-top: 50px;
    width: 80%;
  }
  .bannerInner h1 {
    font-size: 40px;
    font-weight: bolder;
    line-height: 1;
    margin-bottom: 10px;
  }
  .bannerInner h2 {
    font-weight: 500;
    line-height: 1.1;
  }
  .inputt {
    width: 85%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }

  .inputt input {
    width: 93%;
    height: 40px;
    outline: none;
    border: none;
    padding-left: 3%;
  }
  .inputt input::placeholder {
    font-weight: 400;
    font-size: 16px;
  }
  .inputt button {
    width: 136.83px;
    height: 57px;
    border: none;
    background-color: #ffd400;
    color: black;
    font-weight: bold;
    font-size: 18px;
    border: none;
  }
  .inputt a {
    width: 136.83px;
  }
}

@media screen and (max-width: 630px) {
  .banner {
    /* margin-top: -150px; */
    background: linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)),
    url("../images/CMA-Background.png");
    background-position: center center !important;
    background-repeat: no-repeat;
    width: 100%;
    /* background-size: 100%; */
    /* height: 620px; */
    padding: 95px 0px !important;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    background-size: cover;
  }

  .bannerInner {
    margin-top: 50px;
    width: 80%;
  }
  .bannerInner h1 {
    font-size: 40px;
    font-weight: bolder;
    line-height: 1;
    margin-bottom: 10px;
  }
  .bannerInner h2 {
    font-weight: 500;
    line-height: 1.1;
  }
  .inputt {
    width: 85%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }

  .inputt input {
    width: 93%;
    height: 40px;
    outline: none;
    border: none;
    padding-left: 3%;
  }
  .inputt input::placeholder {
    font-weight: 400;
    font-size: 16px;
  }
  .inputt button {
    width: 100%;
    height: 57px;
    border: none;
    background-color: #ffd400;
    color: black;
    font-weight: bold;
    font-size: 18px;
    border: none;
  }
  .inputt a {
    width: 136.83px;
  }

  .input-field {
    width: 100% !important;
  }
}

@media screen and (max-width: 830px) and (min-width: 630px) {
  .inputt button {
    width: 136.83px;
    height: 45px;
    border: none;
    background-color: #ffd400;
    color: black;
    font-weight: bold;
    font-size: 18px;
    border: none;
    cursor: pointer;
  }
}

@media screen and (max-width: 1024px) {
  .banner {
    background: linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)),
    url("../images/CMA-Background.png");
    background-position: center center !important;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    /* background-size: 100%; */
    /* height: 600px; */
    padding: 165px 0px;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    font-family: proxima-nova, sans-serif;
    /* filter: brightness(73%); */
    position: relative;
    z-index: 1;
    background-size: cover;
  }
}
