.housesFlorida{
    /* background-image: url("../images/AboutUs_fourth.jpg"); */
    /* background-color: rgba(0, 0, 0, 0.5);  */
    
    /* background-position: center center !important; */
    /* background-repeat: no-repeat;  */
    font-family: proxima-nova, sans-serif;
    background-size: cover !important;
    /* height: 800px; */
    /* padding-top: 54px;
    padding-bottom: 82px; */
    padding: 30px 20px 0px 20px;
    /* margin-top: 54px; */
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
}
.subHeadingTrustedPartner{
    width: 80%;
    text-align: center;
    margin-bottom: 20px !important;
    margin-top: 60px !important;
}
.floridaContactButtonn{
    display: flex;
    justify-content: flex-start;
    margin-Bottom: 60px;
}
.floridaContactButtonn button {
    font-size: 18px;
    color: black;
    width: 100%;
    height: 50px;
    border: none;
    background-color: white;
    font-style: italic;
    cursor: pointer;
    font-family: EB Garamond, sans-serif !important;
    font-weight: 900;
    padding: 0px 45px;
    border: 1px solid #ffd400;
    -webkit-tap-highlight-color: transparent !important; 
  }
  .floridaContactButtonn a {
    display: flex;
    /* justify-content: center; */
    /* font-style: none; */
    text-decoration: none;
    width: 100%;
    /* display: flex; */
    justify-content: flex-start;
  }
  .floridaContactButtonn a button:hover {
    background-color: #ffd400;
    color: #000000;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
.housesFlorida p{
font-size: 31px;
color: black;
/* font-weight: bold; */
margin-bottom: 75px;
}
.floridaProudIconsMain{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    max-width: 1200px;
    row-gap: 80px;
    margin-bottom: 50px;
}
.floridaIconDiv{
    width: 370px;
    height: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.floridaIconDiv img{
width: 45px;
height: 45px;
object-fit: contain;
}

.floridaIconDiv p{
    font-size: 18px;
    color: #231f1f;
    /* font-family: icomoon !important; */
    font-family:   proxima-nova !important;
  
    font-style: normal;
    font-weight: 100;
    width: 80%;
}
.floridaiconPara{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* gap: 35px; */
}
.floridaiconPara p{
    line-height:1.6;
    
    /* margin: 0px !important; */
}

.floridaIconDiv img:hover{
    transform: scale(1.2);
    transition: transform ease 200ms;
    }
.floridaYellow{
    height: 2px;
    background-color: #FFD400;
    width: 45%;
}
.secondParaHousesFlorida{
    margin-top: 0px;
    margin-bottom: 60px;
}
    @media only  screen and (max-width: 630px) {
        .floridaProudIconsMain {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;
            gap: 40px;
        }
        .floridaIconDiv p {
            width: 87%;
        }
        .housesFlorida {

            /* height: 800px; */
            /* padding-top: 54px; */
            padding-bottom: 23px;
            /* margin-top: 54px; */
            display: flex;
            /* justify-content: center; */
            flex-direction: column;
            align-items: center;
        }
        .floridaContactButtonn button {
            font-size: 17px;
            padding: 0px 25px;
         
          }
    } 


    @media only  screen and (max-width: 630px) {
        .floridaProudIconsMain {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;
            gap: 40px;
        }
        .floridaIconDiv {
            width: 100%;
            height: 260px;
        }
        .housesFlorida p{
            font-size: 24px;
           margin-bottom: 10px;
            }
            .secondParaHousesFlorida{
                margin-top: 0px;
                margin-bottom: 30px;
            }

            .floridaiconPara p{
               font-size: 18px;
            }

    }