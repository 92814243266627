.input-field {
  display: flex;
  flex-direction: column;
  text-align: start;
  // align-items: flex-start;
  label {
    color: black !important;
    font-size: 15px;
    padding: 5px 0px 0px 0px;
    text-align: start;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    margin: 0px;
    line-height: 32px;
  }

  input {
    font-size: 15px;
    margin-bottom: 0;
    margin-top: 0;
    color: #000000 !important;
    outline: none;
    padding: 8px;
    display: block;
    height: 39px;
    box-sizing: border-box;
    width: 100%;
    // height: 2.16667rem;
    margin: 0 0 0.88889rem;
    padding: 0.44444rem;
    border: 1px solid #cacaca;
    border-radius: 0;
    background-color: #fff;
    box-shadow: none;
    font-family: proxima-nova, sans-serif;
    font-size: 0.88889rem;
    font-weight: 400;
    // color: #000;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
  }
}
.search {
  border-top: 1px solid #cacaca;
  margin-top: 16px;
  padding: 16px 0px;
  button {
    width: 100%;
    margin-bottom: 8px;
    color: #ffd400;
    background-color: #fff;
    border: 1px solid #ffd400;
    font-style: italic;
    font-family: adobe-garamond-pro, sans-serif;
    font-weight: 700;
    vertical-align: middle;
    cursor: pointer;
    margin: 0 0 1rem;
    padding: 0.85em 2em;
  }
  & :hover {
    background-color: #ffd400;
    color: #fff;
  }
}

.step1 .input-field ul {
  position: absolute;
  top: 66px !important;
  background: white;
  width: calc(100% - 2px);
  border: 1px solid #cacaca;
  padding: 0px;
  list-style: none;
  li {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    span {
      color: #cacaca;
      margin: 0px 10px 0px 0px;
    }
    font-size: 13px !important;
  }
}
.googless {
  display: flex;
}
.googless img {
  width: 95px;
  margin: 0px 10px 10px auto;
}
