.blackLine{
    background-color: #231F1F;
    height: 3px;
    width: 100%;
    margin-bottom: 72px;
}

/* contact button  */
.contactButtonn {
  display: flex;
  justify-content: flex-start;
  margin-top: 32px;
}
.contactButtonn button {
  font-size: 18px;
  color: black;
  width: 93%;
  height: 50px;
  border: none;
  background-color: white;
  font-style: italic;
  cursor: pointer;
  font-family: EB Garamond, sans-serif !important;
  font-weight: 900;
  padding: 0px 45px;
  border: 1px solid #ffd400;
  -webkit-tap-highlight-color: transparent !important; 
}
.contactButtonn a {
  display: flex;
  /* justify-content: center; */
  /* font-style: none; */
  text-decoration: none;
  width: 100%;
  /* display: flex; */
  justify-content: flex-start;
}
.contactButtonn a button:hover {
  background-color: #ffd400;
  color: #000000;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* form ki css */
.selectArrowsInfo {
  position: absolute;
  right: 4px;
  top: 10px;
  font-size: 17px;

  font-weight: bolder;
  color: #ffd400;
  z-index: 22;
}

.AboutInputtt select:focus {
  /* color: black; */
  border: 1px solid #ffd400;
}

.AboutInputtt:focus {
  /* color: black; */
  border: 1px solid #ffd400;
}
.AboutInputtt {
  position: relative;
  width: 92.5%;
  background-color: white;
  border: 1px solid #d6d6d6;
  outline: none;
  height: 37px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border-radius: none !important;
  opacity: 0.8;
  margin-top: 0.5px;
}
.AboutInputttSmall { 
  position: relative;
  width: 44%;
  background-color: white;
  border: 1px solid #d6d6d6;
  outline: none;
  height: 37px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border-radius: none !important;
  opacity: 0.8;
  margin-top: 0.5px;

  align-self: baseline;
}
.info {
  background-color: #000000;
  /* height: 500px; */
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  color: white;
  /* padding-top: 15px; */
  padding: 15px 20px 15px 30px;
}

.line2 {
  width: 75%;
  background-color: white;
  height: 1px;
  margin-top: 9px;
  margin-bottom: 17px;
  opacity: 0.4;
}
.lineSpace {
  height: 25px;
}
.AboutsConnected {
  width: 90%;
  display: flex;
  flex-direction: column;
}
.AboutFormm {
  background-color: white;
  border: 1px solid #d6d6d6;
  outline: none;
  height: 35px;
  width: 41.5%;
  font-weight: lighter;
  font-size: 16px;
  color: #000000;
}

.AboutformMain input {
  padding-left: 2%;
}
.AboutFormm:focus {
  border: 1px solid #ffd400;
  transition: background-color 0.3s ease;
}

.ContactSelect:focus {
  border: 1px solid #ffd400;
  /* border: 1px solid #FFD400; */
  transition: background-color 0.3s ease;
}

.ContactSelect {
  background-color: white;
  border: 1px solid #d6d6d6;
  outline: none;
  height: 37px;
  width: 100%;
  color: black;
  font-weight: bold;
  font-size: 18px;
  border-radius: none !important;
  opacity: 0.8;
  appearance: none;
  padding-left: 2%;
  z-index: 44;
}
.ContactSelectSmall {
  background-color: white;
  border: 1px solid #d6d6d6;
  outline: none;
  height: 37px;
  width: 100%;
  color: black;
  font-weight: bold;
  font-size: 18px;
  border-radius: none !important;
  opacity: 0.8;
  appearance: none;
  padding-left: 2%;
  z-index: 44;
}

.ContactSelect.select {
  font-family: proxima-nova, sans-serif;
}
.stateRed {
  font-size: 18px !important;
  color: #ffd400;
  margin: 6px 0px;
  cursor: pointer;
  font-weight: 600;
}

.AboutformMain {
  display: flex;
  flex-direction: column;
  gap: 17px;
}
.AboutformMain input {
}

.AboutFormm::placeholder {
  color: #c1c1c1;
  font-weight: normal;
  font-family: proxima-nova, sans-serif;
  font-weight: normal;
  font-size: 16px;
}

/* .AboutsConnected button {
    background-color: #000000;
    border: 1px solid white;
    color: white;
    height: 35px;
    font-size: 15px;
    width: 93%;
    margin-top: 18px;
    font-style: italic;
    font-weight: bold;
    font-family: "EB Garamond", sans-serif !important;
  } */

/* .AboutsConnected button:hover {
    background-color: #ffd400;
    color: black;
    transition: background-color 0.3s ease;
  }
   */
.AboutsConnected p {
  margin: 0;
  font-style: italic;
}

.footerLine {
  color: white;
  background-color: #000000;
  /* height: 5px; */
  padding: 0px 10px 30px 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  text-align: left;
}

.footerLine p {
  display: flex;
  width: 95%;
  color: white;
  opacity: 0.3;
  font-size: 13px;
  text-align: left;
  margin-top: 20px;
}

.linee {
  width: 95%;
  background-color: white;
  height: 1px;
  opacity: 0.2;
}
.AboutsConnected h4 {
  margin: 0px;
  margin-top: 30px;
  font-size: 22px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.AboutsConnected h6 {
  margin: 0px;
  margin-top: 7px;
  opacity: 0.3;
  font-size: 15px;
  font-weight: 400;
}
.AboutsConnected h4:hover {
  color: #ffd400;
  /* transition: color 0.3s ease; */
}
.AboutParas {
  font-size: 13.5px !important;
  display: flex;
 
  gap: 1%;
  margin: 4px 0px 0px 15px;
}

.AboutParas p:hover {
  color: #ffd400;
}
.AboutParas p {
  /* font-weight: 600 !important; */
  color: #666666;
  opacity: 1;
  font-size: 13.5px;
  cursor: pointer;
}
.twoInputs {
  display: flex;
  gap: 16px;
}
.ContacttextArea {
  width: 89.5%;
  height: 160px;
  border: 1px solid #d6d6d6;
  resize: none;
  padding-left: 2%;
  padding-top: 5px;
  background-color: white;
  outline: none;
  font-size: 16px;
}
.ContacttextArea:focus{
  border: 1px solid #ffd400;
}
.ContacttextArea::placeholder {
  font-weight: normal;
}
.message {
  display: flex;
  justify-content: flex-start;
}
/* end */

.ContactContact {
  background-color: #f3f3f3;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  font-family: proxima-nova, sans-serif;
  text-align: center;
  padding: 72px 0px;
}
.contactParaImage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 55%;
  justify-content: center;
  margin-right: 50px;
  /* padding: 60px 0px; */
}
.contactParaImage h3 {
  color: black;
  font-size: 31px;
  font-weight: lighter;
  margin-bottom: 36px;
  margin-top: 0px;
}
.contactPara {
  width: 85%;
  text-align: left;
}

.contactPara h3 {
  color: black;
  font-size: 25px;
  font-weight: lighter;
  margin-bottom: 0px;
  margin: 0px;
}

.contactPara p {
  font-size: 18px;
  line-height: 1.6;
  width: 90%;
}

.expert {
  font-size: 25px;
  color: #231f1f;
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
}

@media only screen and (max-width: 1023px) {
    .ContactContact{
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
  .contactPara {
    width: 80%;
    padding: 0px 50px;
    text-align: left;
  }
  .contactPara p{
    width: 95%;
  }
  .contactParaImage {
    width: 65%;
    padding: 49px;
    display: flex;
    justify-content: baseline;
    align-items: center;
  }
  .AboutformMain{
    align-items: center;
  }
  .contactButtonn a{
    align-items: center;
    justify-content: center;
  }
  .AboutFormm {
    background-color: white;
    border: 1px solid #d6d6d6;
    outline: none;
    height: 35px;
    width: 47%;
    font-weight: normal;
    font-size: 16px;
    color: #000000;
  }
  .message{
    width: 93%;
  }
  .ContacttextArea{
    width: 100%;
  }
  .twoInputs{
    width: 93%;
  }
  .contactButtonn button {
    height: 44px;
  }
  .contactParaImage h3 {
    display: flex;
    align-self: baseline;
    padding-left: 8%;
  }
  .AboutParas{
    gap: 1%;
    margin: 4px 0px 0px 35px;
  }
  .AboutInputttSmall { 
margin-left: 20px;

  }
}

@media only screen and (max-width: 630px) {
  .ContactContact {
    background-color: #f3f3f3;
    display: flex;
    flex-direction:column-reverse;
    justify-content: center;
    align-items: flex-start;
    font-family: proxima-nova, sans-serif;
    text-align: center;
    padding-top: 0px;
  }
  .contactParaImage {
    width: 100%;
    padding: 40px 0px;
    align-items: center;
  }
  .contactParaImage h3 {
    color: black;
    font-size: 24px;
    font-weight: lighter;
    margin-bottom: 36px;
    margin-top: 72px;
    margin-left: 7%;
    display: flex;
    align-self: baseline;
    padding-left: 0px;
}
  .AboutFormm {
    background-color: white;
    border: 1px solid #d6d6d6;
    outline: none;
    height: 35px;
    width: 47%;
    font-weight: normal;
    font-size: 16px;
    color: #000000;
  }
  .message{
    width: 93%;
  }
  .ContacttextArea{
    width: 100%;
  }
  .twoInputs{
    display: flex;
    flex-direction: column;
    width: 93%;
  }
  .contactButtonn button {
    height: 44px;
  }
  .AboutformMain input {
    width: 97%;
}
.contactPara {
  width: 80%;
    padding: 0px 50px;
    text-align: left;
}
.AboutInputttSmall { 
  width: 100%;
}
.AboutInputttSmall {
  margin-left: 15px;
  position: relative;
  width: 92.5%;
}
}

@media only screen and (max-width: 500px) {
    .ContactContact {
        background-color: #f3f3f3;
        display: flex;
        flex-direction:column-reverse;
        justify-content: center;
        align-items: flex-start;
        font-family: proxima-nova, sans-serif;
        text-align: center;
        padding-top: 0px;
      }
      .contactParaImage {
        width: 100%;
        padding: 40px 0px;
        align-items: center;
      }
      .contactParaImage h3 {
        color: black;
        font-size: 24px;
        font-weight: lighter;
        margin-bottom: 36px;
        margin-top: 72px;
        margin-left: 7%;
        display: flex;
        align-self: baseline;
        padding-left: 0px;
    }
      .AboutFormm {
        background-color: white;
        border: 1px solid #d6d6d6;
        outline: none;
        height: 35px;
        width: 47%;
        font-weight: normal;
        font-size: 16px;
 
      }
      .message{
        width: 93%;
      }
      .ContacttextArea{
        width: 100%;
      }
      .twoInputs{
        display: flex;
        flex-direction: column;
        width: 93%;
      }
      .contactButtonn button {
        height: 44px;
      }
      .AboutformMain input {
        width: 97%;
    }
    .contactPara {
        width: 75%;
        padding: 0px 50px;
        text-align: left;
    }
    .contactPara p{
      width: 100%;
    }
}
