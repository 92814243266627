.aboutHistoryMain{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.historyHeading{
    font-size: 31px;
    font-weight: 900;
    color: black;

    margin-top: 72px;
}
.historyPara{
    font-size: 18px;
    width: 69%;
    margin-top: 0px;
    line-height: 1.5;
    color: #231F1F;
    font-weight: 500;
}

@media only  screen and (max-width: 1023px) {
    .historyPara{
        width: 55%;
    }
}

@media only  screen and (max-width: 630px) {
    .historyPara{
        width: 85%;
    }
}