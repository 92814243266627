.dotsCarousel {
    position: absolute;
    bottom: 30px;
    left: 70px !important;
    display: flex;
    cursor: pointer;
    background-color: none;
    width: 86% !important;
    outline: none !important;
    -webkit-tap-highlight-color: transparent !important;
  }
  
  .dotsCarousel:active {
    background-color: transparent !important; /* Set the background color to transparent on click */
    /* Other styles for the active state */
  }
@media screen and (max-width: 1023px) {
    .dotsCarousel {
        position: absolute;
        bottom: 30px;
        left: 0px !important;
        display: flex;
        cursor: pointer;
        width: 100% !important;
        justify-content: center;
        background-color: none;
      }
      
}