.floridaSecondSec {
    background-color: #000000;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  }
  
  .floridaSecondPara {
    width: 80%;
  
    justify-content: flex-start;
    align-items: center;
    color: white;
    font-weight: 400;
    font-size: 31px;
  }
  
  .secondRed {
    color: #ffd400;
    font-weight: 700;
  }
  
  @media screen and (max-width: 490px) {
    .floridaSecondSec {
      background-color: #000000;
      height: 250px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      text-align: center;
    }
  
    .floridaSecondPara {
      width: 80%;
  
      justify-content: flex-start;
      align-items: center;
      color: white;
      font-weight: 400;
      font-size: 24px;
    }
  
    .secondRed {
      color: #ffd400;
    }
  }
  
  @media screen and (max-width: 750px) and (min-width: 500px) {
    .floridaSecondSec {
      background-color: #000000;
      height: 250px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      text-align: center;
    }
  
    .floridaSecondPara {
      width: 80%;
  
      justify-content: flex-start;
      align-items: center;
      color: white;
      font-weight: 400;
      font-size: 24px;
    }
  
    .secondRed {
      color: #ffd400;
    }
    .companyReviews img {
      width: 32% !important;
      object-fit: contain;
    }
  }
  
  @media screen and (max-width: 1000px) and (min-width: 750px) {
    .floridaSecondSec {
      background-color: #000000;
      height: 250px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      text-align: center;
    }
  
    .floridaSecondPara {
      width: 80%;
  
      justify-content: flex-start;
      align-items: center;
      color: white;
      font-weight: 400;
      font-size: 24px;
    }
  
    .secondRed {
      color: #ffd400;
    }
  }
  