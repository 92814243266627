.graphs {
  background-color: white;
  padding: 50px;
  display: flex;
  justify-content: center;
  background-color: #f3f3f3;
  margin-top: 22px;
}

.graphsMain {
  padding-left: 20px;
}

.graphGrey {
  width: 153px;
  background-color: #cacaca;
  z-index: 2;
  transition: transform 1s ease;
  transform-origin: bottom;
  bottom: 0px;
  z-index: 0;
}

.graphGreyTwo {
  position: absolute;
  background-color: #cacaca;
  height: 60;
  bottom: 113;
  left: 0;
  color: black;
  display: flex;
  justify-content: center;
  align-items: "center";
  font-weight: bold;
  font-size: "30.15px";
  z-index: 1;
}

.expanded {
  transform: scaleY(1);
}

.graphPinkTwo {
  background-color: #ffd400;
  display: flex;
  z-index: 1;
}

.graphPink {
  width: 153px;
  background-color: #ffd400;
  display: flex;
  z-index: 1;
  bottom: 0px;
  left: 153px;
  /* flex-direction: column; */
  /* transform: rotate(180deg); */
}

.AverageDays {
  display: flex;
  /* width: 30%; */
  justify-content: left;
  width: 70%;
  margin-left: 4%;
  gap: 5%;
}

.AverageDaysTwo {
  display: flex;
  justify-content: end;
  margin-bottom: 19px;
  margin-right: 13px;
  align-items: end;
  width: 75%;
  /* margin-left: 40px; */
  display: flex;
  flex-direction: column;
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
}

.AverageDaysTwo p {
  width: 40%;
  display: flex;
  text-align: center;
  text-align: right;
  margin-bottom: 5px;
}

.AverageDays p {
  width: 40%;
  display: flex;
  text-align: center;
}

.graphsMainTwo {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

.graphTwoRelative {
  display: flex;
  gap: 92.5px;
  position: relative;
}

.graphIcons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  /* margin-right: 100px; */
  gap: 45px;
  margin-top: -60px;
  margin-bottom: 72px;
  font-style: italic;
  font-size: 14.4px;
  font-family: proxima-nova, sans-serif;
}

.greyCircle {
  width: 36px;
  height: 36px;
  background-color: #cacaca;
  border-radius: 50%;
}

.yellowCircle {
  width: 36px;
  height: 36px;
  background-color: #ffd400;
  border-radius: 50%;
}

.circleNpara {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;
}

.circleNparaTwo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;
  padding-right: 60px;
}

.graphLine {
  height: 1px;
  width: 460px;
}
@media only screen and (min-width: 1201px) and (max-width: 1500px) {
  .graphLine{
    width: 440px;
  }
  .graphTwoRelative {
    display: flex;
    gap: 80px;
    position: relative;
  }
  .graphGreyTwo {
    width: 255px !important;
    background-color: #cacaca;
    z-index: 1;
    transition: transform 1s ease;
    transform-origin: bottom;
  
    bottom: 0;
    left: 0px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 30.15px;
  }
  
  .graphPinkTwo {
    width: 267px !important;
    background-color: #ffd400;
    display: flex;
    z-index: 0;
    bottom: 0;
    left: 153px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 30.15px;
  }
  .AverageDaysTwo p {
    width: 45%;
  }
  .AverageDaysTwo{
  width: 80%;
  }
  .AverageDays {
    margin-left: 1%;
    gap:7%;
  }
}
@media only screen and (min-width: 1100px) and (max-width: 1200px) {
.graphLine{
  width: 440px;
}
.graphTwoRelative {
  display: flex;
  gap: 80px;
  position: relative;
}
.graphGreyTwo {
  width: 255px !important;
  background-color: #cacaca;
  z-index: 1;
  transition: transform 1s ease;
  transform-origin: bottom;

  bottom: 0;
  left: 0px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 30.15px;
}

.graphPinkTwo {
  width: 267px !important;
  background-color: #ffd400;
  display: flex;
  z-index: 1;
  bottom: 0;
  left: 153px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 30.15px;
}
.AverageDaysTwo p {
  width: 65%;
}
.AverageDaysTwo{
width: 80%;
}
}

@media only screen and (min-width: 1024px) and (max-width: 1100px) {
  .graphLine{
    width: 440px;
  }
  .graphTwoRelative {
    display: flex;
    gap: 80px;
    position: relative;
  }
  .graphGreyTwo {
    width: 255px !important;
    background-color: #cacaca;
    z-index: 1;
    transition: transform 1s ease;
    transform-origin: bottom;
  
    bottom: 0;
    left: 0px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 30.15px;
  }
  
  .graphPinkTwo {
    width: 267px !important;
    background-color: #ffd400;
    display: flex;
    z-index: 1;
    bottom: 0;
    left: 153px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 30.15px;
  }
  .AverageDaysTwo p {
    width: 95%;
  }
  .AverageDaysTwo{
  width: 80%;
  }
  }


@media only screen and (max-width: 1023px) {
  .graphs {
    display: flex;
    flex-direction: column;
  }

  .graphsMain {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 2%;
  }

  .AverageDays {
    display: flex;
    width: 33%;
    justify-content: left;
    width: 37%;
    margin-left: 5%;
    gap: 3%;
}
  .AverageDays p {
    width: 19%;
    display: flex;
    text-align: center;
  }

  .graphTwo {
    margin-top: 100px;
  }

  .graphIcons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    /* margin-right: 100px; */
    gap: 45px;
    margin-top: 5px;
    margin-bottom: 72px;
  }

  .AverageDays p {
    width: 100%;
    display: flex;
    text-align: center;
  }

  .graphLine {
    height: 1px;
    width: 280px !important;
  }

  .graphGrey {
    width: 153px;
    background-color: #cacaca;
    z-index: 0;
    transition: transform 1s ease;
    transform-origin: bottom;

    bottom: 0;
    left: 0px;
    /* Assuming you meant to add "px" here */
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 30.15px;
  }

  .graphPink {
    width: 153px;
    background-color: #ffd400;
    display: flex;
    z-index: 1;
    bottom: 0;
    left: 153px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 30.15px;
  }
}
@media only screen and (max-width: 800px) {
  

.AverageDaysTwo p {
    width: 75%;
}
}
@media only screen and (max-width: 400px) {
  .graphLine {
    height: 1px;
    width: 280px !important;
  }

  .graphGrey {
    width: 153px;
    background-color: #cacaca;
    z-index: 0;
    transition: transform 1s ease;
    transform-origin: bottom;

    bottom: 0;
    left: 0px;
    /* Assuming you meant to add "px" here */
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 30.15px;
  }

  .graphPink {
    width: 153px;
    background-color: #ffd400;
    display: flex;
    z-index: 1 !important;
    bottom: 0;
    left: 153px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 30.15px;
  }

  .AverageDays p {
    width: 100%;
    display: flex;
    text-align: center;
  }

  .AverageDays {
    display: flex;
    /* width: 30%; */
    justify-content: left;
    width: 100%;
    margin-left: 9%;
    gap: 18%;
  }

}

@media only screen and (max-width: 500px) {
  .graphTwoRelative {
    display: flex;
    gap: 45px;
    position: relative;
  }
  .graphGreyTwo {
    width: 146px !important;
    background-color: #cacaca;
    z-index: 1;
    transition: transform 1s ease;
    transform-origin: bottom;

    bottom: 0;
    left: 0px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 30.15px;
  }
  .AverageDays {
    display: flex;
    /* width: 30%; */
    justify-content: left;
    width: 75%;
    margin-left: 5%;
    gap: 18%;
  }

  .graphPinkTwo {
    width: 158px !important;
    background-color: #ffd400;
    display: flex;
    z-index: 1;
    bottom: 0;
    left: 153px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 30.15px;
  }
  .AverageDaysTwo p {
    width: 100%;
  }
  .AverageDaysTwo{
width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .graphTwoRelative {
    display: flex;
    gap: 35px;
    position: relative;
  }
  .graphGreyTwo {
    width: 115px !important;
    background-color: #cacaca;
    z-index: 1;
    transition: transform 1s ease;
    transform-origin: bottom;

    bottom: 0;
    left: 0px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 30.15px;
  }
  .AverageDays {
    display: flex;
    width: 90%;
    justify-content: left;
    /* width: 5%; */
    margin-left: 5%;
    gap: 18%;
  }
  .graphPinkTwo {
    width: 125px !important;
    background-color: #ffd400;
    display: flex;
    z-index: 1;
    bottom: 0;
    left: 153px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 30.15px;
  }
  .AverageDaysTwo p {
    width: 100%;
  }
  .AverageDaysTwo{
width: 100%;
  }
}