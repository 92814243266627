.control-dots{
    display: none !important;
    z-index: 0;
    background-color: transparent;
    position: absolute !important;
    left: 20px !important;
    /* width: 20px; */
    bottom: 0px !important;
    display: flex;
    align-items: flex-end;
    width: none;
    width: 180px!important;
    margin-left: 62px !important;
    margin-bottom: 40px !important;
    margin-top: 420px !important;
    height: 50px;

}


.card{
    width: 90%;
    /* padding-bottom: 35px !important; */
}

.carousel .control-dots .dot {

    background: black!important;
    border-radius: 0%!important;
    width: 60px!important;
    height: 3px!important;
    opacity: 1 !important;
    padding: 0px !important;
    box-shadow: none!important;
    margin:0px 1.5px !important;

    
}
/* .carousel-status{
    display: none !important;
} */
.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
    background: #ffd400!important;
    width: 60px!important;
    height: 3.5px!important;
    opacity: 1;
    filter: alpha(opacity=100);
    box-shadow: none!important;
}
.carousel .carousel-slider ul{
bottom: 0px;
}

.carousel .slider-wrapper.axis-horizontal .slider{
    padding: 40px 0px !important;
}
.carousel .slider-wrapper.axis-horizontal .slider .slide{
    background-color: #EFEFEF!important;
}
.carousel .thumbs-wrapper{
    display: none !important;
}
.slider-wrapper{
    width: 93% !important;
}
/* .carousel .slider-wrapper{
    background-color: #EFEFEF!important;
} */

element.style {
    transform: translate3d(-100%, 0px, 0px);
}
.carousel .slider-wrapper.axis-horizontal .slider {
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}
.carousel .slider-wrapper.axis-horizontal .slider {
    padding: 40px 0px !important;
    background-color: #f3f3f3;
}
.carousel .slider.animated {
    background-color: #EFEFEF!important;
}

@media screen and (max-width: 1023px) {
    .card{
        width: 100%;
    }
    .control-dots{
        display: none !important;
        z-index: 0;
        background-color: transparent;
        position: absolute !important;
        /* left: 20px !important; */
        /* width: 20px; */
        bottom: 0px !important;
        display: flex;
        align-items: flex-end;
        width: 100%!important;
        margin-left: -20px !important;
        margin-bottom: 20px !important;
        display: flex;
        justify-content: center;
        margin-top: 650px !important;
        height: 50px;
    }
    .slider-wrapper{
        width: 100% !important;
    }
}
@media screen and (min-width: 250px) and (max-width: 300px) {

    .control-dots{
        display: none !important;
        z-index: 0;
        background-color: transparent;
        position: absolute !important;
        /* left: 20px !important; */
        /* width: 20px; */
        bottom: 0px !important;
        display: flex;
        align-items: flex-end;
        width: 100%!important;
        margin-left: -20px !important;
        margin-bottom: 20px !important;
        display: flex;
        justify-content: center;
        margin-top: 955px !important;
        height: 50px;
    }
}
@media screen and (min-width: 300px) and (max-width: 380px) {

    .control-dots{
        display: none !important;
        z-index: 0;
        background-color: transparent;
        position: absolute !important;
        /* left: 20px !important; */
        /* width: 20px; */
        bottom: 0px !important;
        display: flex;
        align-items: flex-end;
        width: 100%!important;
        margin-left: -20px !important;
        margin-bottom: 20px !important;
        display: flex;
        justify-content: center;
        margin-top: 615px !important;
        height: 50px;
    }
}
@media screen and (min-width: 381px) and (max-width: 389px) {

    .control-dots{
        display: none !important;
        z-index: 0;
        background-color: transparent;
        position: absolute !important;
        /* left: 20px !important; */
        /* width: 20px; */
        bottom: 0px !important;
        display: flex;
        align-items: flex-end;
        width: 100%!important;
        margin-left: -20px !important;
        margin-bottom: 20px !important;
        display: flex;
        justify-content: center;
        margin-top: 620px !important;
        height: 50px;
    }
}
@media screen and (min-width: 389px) and (max-width: 410px) {

    .control-dots{
        display: none !important;
        z-index: 0;
        background-color: transparent;
        position: absolute !important;
        /* left: 20px !important; */
        /* width: 20px; */
        bottom: 0px !important;
        display: flex;
        align-items: flex-end;
        width: 100%!important;
        margin-left: -20px !important;
        /* margin-bottom: 20px !important; */
        display: flex;
        justify-content: center;
        margin-top: 600px !important;
        /* margin-bottom: -380px !important; */
        height: 50px;
    }
}
@media screen and (min-width: 410px) and (max-width: 425px) {

    .control-dots{
        display: none !important;
        z-index: 0;
        background-color: transparent;
        position: absolute !important;
        /* left: 20px !important; */
        /* width: 20px; */
        bottom: 0px !important;
        display: flex;
        align-items: flex-end;
        width: 100%!important;
        margin-left: -20px !important;
        margin-bottom: 20px !important;
        display: flex;
        justify-content: center;
        margin-top: 580px !important;
        height: 50px;
    }
}


@media screen and (min-width: 425px) and (max-width: 450px) {

    .control-dots{
        display: none !important;
        z-index: 0;
        background-color: transparent;
        position: absolute !important;
        /* left: 20px !important; */
        /* width: 20px; */
        bottom: 0px !important;
        display: flex;
        align-items: flex-end;
        width: 100%!important;
        margin-left: -20px !important;
        margin-bottom: 20px !important;
        display: flex;
        justify-content: center;
        margin-top: 550px !important;
        height: 50px;
    }
}
@media screen and (min-width: 450px) and (max-width: 500px) {

    .control-dots{
        display: none !important;
        z-index: 0;
        background-color: transparent;
        position: absolute !important;
        /* left: 20px !important; */
        /* width: 20px; */
        bottom: 0px !important;
        display: flex;
        align-items: flex-end;
        width: 100%!important;
        margin-left: -20px !important;
        margin-bottom: 20px !important;
        display: flex;
        justify-content: center;
        margin-top: 535px !important;
        height: 50px;
    }
}
@media screen and (min-width: 500px) and (max-width: 625px) {

    .control-dots{
        display: none !important;
        z-index: 0;
        background-color: transparent;
        position: absolute !important;
        /* left: 20px !important; */
        /* width: 20px; */
        bottom: 0px !important;
        display: flex;
        align-items: flex-end;
        width: 100%!important;
        margin-left: -20px !important;
        margin-bottom: 20px !important;
        display: flex;
        justify-content: center;
        margin-top: 470px !important;
        height: 50px;
    }
}
@media screen and (max-width: 640px) {
    .card{
        width: 100%;
    }
}

@media screen and (min-width: 625px) and (max-width: 700px) {

    .control-dots{
        display: none !important;
        z-index: 0;
        background-color: transparent;
        position: absolute !important;
        /* left: 20px !important; */
        /* width: 20px; */
        bottom: 0px !important;
        display: flex;
        align-items: flex-end;
        width: 100%!important;
        margin-left: -20px !important;
        margin-bottom: 20px !important;
        display: flex;
        justify-content: center;
        margin-top: 490px !important;
        height: 50px;
    }
}

@media screen and (min-width: 700px) and (max-width: 800px) {

    .control-dots{
        display: none !important;
        z-index: 0;
        background-color: transparent;
        position: absolute !important;
        /* left: 20px !important; */
        /* width: 20px; */
        bottom: 0px !important;
        display: flex;
        align-items: flex-end;
        width: 100%!important;
        margin-left: -20px !important;
        margin-bottom: 20px !important;
        display: flex;
        justify-content: center;
        margin-top: 470px !important;
        height: 50px;
    }
}

@media screen and (min-width: 800px) and (max-width: 900px) {

    .control-dots{
        display: none !important;
        z-index: 0;
        background-color: transparent;
        position: absolute !important;
        /* left: 20px !important; */
        /* width: 20px; */
        bottom: 0px !important;
        display: flex;
        align-items: flex-end;
        width: 100%!important;
        margin-left: -20px !important;
        margin-bottom: 20px !important;
        display: flex;
        justify-content: center;
        margin-top: 430px !important;
        height: 50px;
    }
}

@media screen and (min-width: 900px) and (max-width: 1023px) {

    .control-dots{
        display: none !important;
        z-index: 0;
        background-color: transparent;
        position: absolute !important;
        /* left: 20px !important; */
        /* width: 20px; */
        bottom: 0px !important;
        display: flex;
        align-items: flex-end;
        width: 100%!important;
        margin-left: -20px !important;
        margin-bottom: 20px !important;
        display: flex;
        justify-content: center;
        margin-top: 410px !important;
        height: 50px;
    }
}
@media screen and (min-width: 1024px) and (max-width: 1100px) {

    .control-dots{
        display: none !important;
        z-index: 0;
        background-color: transparent;
        position: absolute !important;
        /* left: 20px !important; */
        /* width: 20px; */
        bottom: 0px !important;
        display: flex;
        align-items: flex-end;
        width: 100%!important;
        margin-left: 53px !important;
        margin-bottom: 20px !important;
        margin-top: 470px !important;
        height: 50px;
    }
}
@media screen and (min-width: 1100px) and (max-width: 1400px) {

    .control-dots{
        display: none !important;
        z-index: 0;
        background-color: transparent;
        position: absolute !important;
        /* left: 20px !important; */
        /* width: 20px; */
        bottom: 0px !important;
        display: flex;
        align-items: flex-end;
        width: 100%!important;
        margin-left: 53px !important;
        margin-bottom: 20px !important;
        margin-top: 434px !important;
        height: 50px;
    }
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {

    .control-dots{
        display: none !important;
        z-index: 0;
        background-color: transparent;
        position: absolute !important;
        /* left: 20px !important; */
        /* width: 20px; */
        bottom: 0px !important;
        display: flex;
        align-items: flex-end;
        width: 100%!important;
        margin-left: 80px !important;
        margin-bottom: 20px !important;
        margin-top: 450px !important;
        height: 50px;
    }
}
@media screen and (min-width: 1600px) and (max-width: 1800px) {

    .control-dots{
        display: none !important;
        z-index: 0;
        background-color: transparent;
        position: absolute !important;
        /* left: 20px !important; */
        /* width: 20px; */
        bottom: 0px !important;
        display: flex;
        align-items: flex-end;
        width: 100%!important;
        margin-left: 80px !important;
        margin-bottom: 20px !important;
        margin-top: 500px !important;
        height: 50px;
    }
}
@media screen and (min-width: 1800px) and (max-width: 2000px) {

    .control-dots{
        display: none !important;
        z-index: 0;
        background-color: transparent;
        position: absolute !important;
        /* left: 20px !important; */
        /* width: 20px; */
        bottom: 0px !important;
        align-items: flex-end;
        width: 100%!important;
        margin-left: 90px !important;
        margin-bottom: 20px !important;
        display: flex;
        margin-top: 580px !important;
        height: 50px;
    }
}
@media screen and (min-width: 2000px) and (max-width: 2500px) {

    .control-dots{
        display: none !important;
        z-index: 0;
        background-color: transparent;
        position: absolute !important;
        /* left: 20px !important; */
        /* width: 20px; */
        bottom: 0px !important;
        align-items: flex-end;
        width: 100%!important;
        margin-left: 100px !important;
        margin-bottom: 15px !important;
        display: flex;
        margin-top: 650px !important;
        height: 50px;
    }
}
@media screen and (min-width: 2500px) and (max-width: 2800px) {

    .control-dots{
        display: none !important;
        z-index: 0;
        background-color: transparent;
        position: absolute !important;
        /* left: 20px !important; */
        /* width: 20px; */
        bottom: 0px !important;
        align-items: flex-end;
        width: 100%!important;
        margin-left: 100px !important;
        margin-bottom: 20px !important;
        display: flex;
        margin-top: 790px !important;
        height: 50px;
    }
}