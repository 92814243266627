.input-field {
  display: flex;
  flex-direction: column;
  text-align: start;
}
.input-field h4 {
  font-size: 15px;
  font-weight: 300;
  padding: 5px 0px 0px 0px;
  text-align: start;
  margin: 0px;
}
.input-field input {
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 0;
  padding: 8px;
}/*# sourceMappingURL=input.css.map */