.AboutStoryBlack {
    background-color: #000000;
    /* height: 433px; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    padding: 18px 36px;
    align-items: flex-start;
    text-align: left;
  }
  .AboutSecondRed{
    color: #ffd400;
    font-size: 31px !important;
    margin-bottom: 0px;
  }
  .AboutSecondPara {
    width: 85%;
    justify-content: flex-start;
    align-items: flex-start;
    color: white;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.6;
/* margin-bottom: 100px; */
  }
  
  .SellSecondRed {
    color: #ffd400;
    font-size: 31px;
    font-weight: 100;
    margin: 0px;
  }
  
.AboutStoryBlack button {
    font-size: 18px;
    color: white;
    width: 100%;
    height: 50px;
    border: none;
    background-color: transparent;
    font-style: italic;
    cursor: pointer;
    font-family: EB Garamond, sans-serif !important;
    font-weight: 900;
    border: 1px solid #ffd400;
    margin-bottom: 18px;
    -webkit-tap-highlight-color: transparent !important; 
  }


  .AboutStoryBlack a {
    width: 186.61px;
  }
  .AboutStoryBlack a button:hover {

    background-color: #ffd400;
    color: #000000;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  @media only  screen and (max-width: 1023px) {
    .AboutStoryBlack {
        background-color: #000000;

        display: flex;
        flex-direction: column;
        justify-content: center;
        color: white;
        padding: 36px 25%;
        align-items: flex-start;
        text-align: left;
    }
    .AboutSecondPara {
      width: 100%;
    }
  }

  @media only  screen and (max-width: 630px) {
    .AboutStoryBlack {
        background-color: #000000;
        /* height: 433px; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: white;
        padding: 36px 8%;
        align-items: flex-start;
        text-align: left;
    }
    .AboutSecondPara {
      width: 100%;
    }
  }