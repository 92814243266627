.AboutAboutMain{
    background-color: #F3F3F3;
    display: flex;
    justify-content: center;
   align-items: flex-start;
   font-family: proxima-nova,sans-serif;
   text-align: center;
   padding: 20px 0px;
}
.paraImage{
    width: 38%;
    padding: 60px 0px;
}

.para{
    width: 50%;
    padding: 2%;
    text-align: left;
}

.paraImage img{
    width: 100%;
}
.para h3{
    color: black;
    font-size: 31px;
    font-weight: lighter;
    margin-bottom: 0px;
}

.para p{
    font-size: 18px;
}
.para h4{
  
}

.expert{
    font-size: 25px;
    color: #231F1F;
    font-family: proxima-nova,sans-serif;
    font-weight: 300;
}
.aboutUsEndLine{
    background-color: #CACACA;
    height: 1px;
    width: 100%;
}
@media only  screen and (max-width: 1023px) {
    .para{
        width: 80%;
        padding: 0px 50px;
        text-align: left;
    }
    .paraImage{
        width: 20%;
        padding: 40px;
        display: flex;
        justify-content: baseline;
    }

    
}

@media only  screen and (max-width: 630px) {
    .AboutAboutMain{
        background-color: #F3F3F3;
        display: flex;
        flex-direction: column;
        justify-content: center;
       align-items: flex-start;
       font-family: proxima-nova,sans-serif;
       text-align: center;
       padding-top: 0px;
    }
    .paraImage {
        width: 100%;
        padding: 0px;
        
    }
    .para{

      margin-bottom: 72px;
    }
}

@media only  screen and (max-width: 500px) {
    .paraImage {
        width: 100%;
        padding: 0px;
    }
    .para{
        width: 80%;
        padding: 0px 10px;
        text-align: left;
      
    }
    .AboutAboutMain {
       
        background-color: #F3F3F3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: proxima-nova,sans-serif;
        text-align: center;
    }
}