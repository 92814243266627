.aboutHistoryMain{
  background-color: #F3F3F3;
}
.aboutIcons {
  cursor: pointer;
  width: 29%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0px;
  flex: 1;
  height: -webkit-fill-available;
  position: relative;
}
.aboutIcons:hover .arrowAnimations {
  top: 220px; 
  transition: top 400ms ease;
  opacity: 1 !important;
}
.aboutIcons:hover{
background-color: #ffd400;
transition: background-color 200ms ease;
}
.mainnnAnimations{
  display: flex;
}
.arrowAnimations{
opacity: 0 !important;
    position: absolute;
    top: 190px; /* Initially positioned above the container */
    left: 45%; /* Center horizontally */
    transform: translateX(-50%);
    width: 0;
    height: 0;

  
    transition: top 0.5s ease; /* Transition for smooth movement */
}
.aboutSecondMain {
    background-color: #000000;
    /* height: 251px; */
    padding: 44px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 50%;
  }

  .AboutSecond {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: white;
    text-align: center;
    width: 95%;
    padding: 18px 36px;
  }

  .AboutSecondPics{
    width: 52%;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 3%;
  }
  .secondParaAbout {
    width: 85%;
    text-align: left;
    justify-content: flex-start;
    align-items: center;
    color: white;
    font-weight: 400;
    font-size: 18px;
  }
  
  .secondRedAbout {
    color: #ffd400;
    font-weight: 700;
    font-size: 31px;
    margin-bottom: 0px;
    text-align: left;
  }

  .aboutIconsMain {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 15px;
  }
  

  .text-center {
    font-size: 25px !important;
  }
  .aboutIcons img {
    transition: transform 0.3s ease;
  }
  
  .aboutIcons h4 {
    font-size: 25px;
    margin-top: 10px;
    margin-bottom: 0px;
    color: white;
    opacity: 1;
    font-weight: normal;
  }
  @media only  screen and (max-width: 1023px) {
    .mainnnAnimations{
      display: flex;
      flex-direction: column;
    }
    .aboutSecondMain {
      display: flex;
flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 0px;
      width: 100%;
    }
  .AboutSecond{
    width: 60%;
    padding: 0px;
    align-items: flex-start;
  }
  .secondRedAbout{
    margin: 0px;
  }
  .aboutIcons{
    padding: 54px 0px;
  }
  .AboutSecondPics{
    width: 100%;
    padding: 0px;
  }
  .aboutIconsMain{
    width: 100%;
    justify-content: space-around;
    padding: 0px 20px;
   
  }
  .aboutIcons:hover .arrowAnimations {
    top: 155px; 
    transition: top 400ms ease;
    opacity: 1 !important;
  }
  /* .arrowAnimations{
    opacity: 0 !important;
        position: absolute;
        top: 90px;
        left: 45%; 
        transform: translateX(-50%);
        width: 0;
        height: 0;
    
      
        transition: top 0.5s ease;
    } */
    .arrowAnimations{
      width: 20px;
      height: 20px;
      object-fit: contain;
      opacity: 1 !important;
      left: 80%;
      top: 152px; 
    }
  }
  @media only  screen and (max-width: 630px) {
.aboutIconsMain{
  flex-direction: column;
  align-items: center;
}

.aboutIcons:hover .arrowAnimations {
  top: 130px;
}
.aboutIcons h4{
  font-size: 18px;
}
/* .arrowAnimations{
opacity: 1;
    position: absolute;
    top: 130px;
    left: 90%; 
    width: 15px !important;
    height: 20.61px !important;
    object-fit: contain;
} */
.arrowAnimations{
  width: 54px;
  height: 54px;
  object-fit: contain;
  opacity: 1 !important;
  left: 80%;
  top: 145px; 
}

  .AboutSecond {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: white;
    text-align: center;
    width: 63%;
  }
.secondRedAbout{
  font-size: 24px;
}
.secondParaAbout {
  width: 100%;

  justify-content: flex-start;
  align-items: center;
  color: white;
  font-weight: 400;
  font-size: 18px !important;
}
.AboutSecond {
  background-color: #000000;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}
.aboutIcons{
 width: 51%;
 position: relative;
}

  }
  @media screen and (max-width: 500px) {
    .AboutSecond {
      background-color: #000000;
      display: flex;
      flex-direction: column;

      align-items: flex-start;
      color: white;
      text-align: center;
      padding: 40px 0px 0px 0px;
    }
  
    .secondParaAbout {
      width: 100%;
  
      justify-content: flex-start;
      align-items: center;
      color: white;
      font-weight: 400;
      font-size: 18px !important;
    }
  
    .secondRedAbout {
      color: #ffd400;
    }
    .aboutSecondMain{
      padding: 62px 0px;
    }
  }
  
  @media screen and (max-width: 750px) and (min-width: 500px) {
    .AboutSecond {
      background-color: #000000;
      /* height: 250px; */
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      color: white;
      text-align: center;
      padding: 40px 0px 0px 0px;
    }
    .secondRedAbout {
      color: #ffd400;
    }
    .companyReviews img {
      width: 32% !important;
      object-fit: contain;
    }
  }
  
  @media screen and (max-width: 1000px) and (min-width: 750px) {
    .AboutSecond {
      background-color: #000000;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      color: white;
      text-align: center;
      width: 50%;
      padding: 40px 0px 0px 0px;
    }
  
    .secondParaAbout {
      width: 100%;
  
      justify-content: flex-start;
      align-items: center;
      color: white;
      font-weight: 400;
      font-size: 18px;
    }
  
    .secondRedAbout {
      color: #ffd400;
    }
  }
  
