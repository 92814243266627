.ContactHistory{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    align-items: center;
}

.AboutHistoryHeading{
    font-size: 25px;
    font-weight: 900;
    color: black;

    margin-top: 72px;
}
.AboutHistoryPara{
    font-size: 18px;
    width: 69%;
    margin-top: 0px;
    line-height: 1.5;
    color: #231F1F;
    font-weight: 500;
}

@media only  screen and (max-width: 1023px) {
    .AboutHistoryPara{
        width: 55%;
    }
}

@media only  screen and (max-width: 630px) {
    .AboutHistoryPara{
        width: 85%;
    }
    .AboutHistoryHeading{
        font-size: 18px;
    }
}