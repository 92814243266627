/* .ModalSelect .ModaltextArea .ModalInputttn .ZIP:focus {
  border: 1px solid #ffd400 !important;
  transition: background-color 0.3s ease;
} */
.zipMain input:focus {
  border: 1px solid #ffd400;
  transition: background-color 0.3s ease;
}
.ModaltextArea:focus {

  border: 1px solid #ffd400 !important;
  transition: border 0.3s ease;
}
.ModalMessage textarea:focus{
  border: 1px solid #ffd400 !important;
  transition: border 0.3s ease;
}
textarea {
  border: none;
}
.ModalInputtt select:focus {
  border: 1px solid #ffd400;
  transition: background-color 0.3s ease;
}
.ModalMessage textarea:focus {
  border: 1px solid #ffd400;
  transition: background-color 0.3s ease;
}
.formModalMain {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: transparent;
}

.crossModal {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.crossModal img {
  width: 20px;
  height: 20px;
}

/* contact button  */
.formModalButtonn {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.formModalButtonn button {
  font-size: 18px;
  color: white;
  width: 90%;
  height: 50px;
  border: none;
  background-color: #231f1f;
  font-style: italic;
  cursor: pointer;
  font-family: EB Garamond, sans-serif !important;
  font-weight: 900;
  padding: 0px 45px;
  border: 1px solid #ffd400;
}

.formModalButtonn a {
  display: flex;
  /* justify-content: center; */
  /* font-style: none; */
  text-decoration: none;
  width: 100%;
  /* display: flex; */
  justify-content: center;
}

.formModalButtonn a button:hover {
  background-color: #ffd400;
  color: #000000;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* form ki css */
.selectArrowsInfo {
  position: absolute;
  right: 11px;
  top: 7px;
  font-size: 22px;

  font-weight: bolder;
  color: #ffd400;
  z-index: 22;
}

.ModalInputtt select:focus {
  /* color: black; */
  border: 1px solid #ffd400;
  transition: background-color 0.3s ease;
}

.ModalInputtt:focus {
  /* color: black; */
  border: 1px solid #ffd400;
  transition: background-color 0.3s ease;
}

.ModalInputtt {
  position: relative;
  width: 92.5%;
  background-color: #231f1f;
  border: 1px solid #d6d6d6;
  outline: none;
  height: 37px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border-radius: none !important;
  opacity: 0.8;
  margin-top: 0.5px;
}

.info {
  background-color: #000000;
  /* height: 500px; */
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  color: white;
  /* padding-top: 15px; */
  padding: 15px 20px 15px 30px;
}

.logoDiv {
  width: 13%;
  margin-top: 15px;
  margin-left: -25px;
}

.logoDiv img {
  width: 165px;
  height: 116px;
}

.line2 {
  width: 75%;
  background-color: white;
  height: 1px;
  margin-top: 9px;
  margin-bottom: 17px;
  opacity: 0.4;
}

.lineSpace {
  height: 25px;
}

.AboutsConnected {
  width: 90%;
  display: flex;
  flex-direction: column;
}

.ModalFormm {
  background-color: #231f1f;
  border: 1px solid #d6d6d6;
  outline: none;
  height: 35px;
  width: 90%;
  font-weight: bold;
  font-size: 16px;
  color: #c1c1c1;
}

.ModalformMain input {
  padding-left: 2%;
}

.ModalFormm:focus {
  border: 1px solid #ffd400;
  transition: background-color 0.3s ease;
}

.ModalSelect:focus {
  border: 1px solid #ffd400;
  /* border: 1px solid #FFD400; */
  transition: background-color 0.3s ease;
}

.ModalSelect {
  background-color: #231f1f;
  border: 1px solid #d6d6d6;
  outline: none;
  height: 37px;
  width: 100%;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border-radius: none !important;
  opacity: 0.8;
  appearance: none;
  padding-left: 2%;
  z-index: 44;
}

.ModalSelect.select {
  font-family: proxima-nova, sans-serif;
}

.stateRed {
  font-size: 18px !important;
  color: #ffd400;
  margin: 6px 0px;
  cursor: pointer;
  font-weight: 600;
}

.ModalformMain {
  display: flex;
  flex-direction: column;
  gap: 17px;
  align-items: center;
}

.ModalformMain input {
}

::placeholder {
  color: #c1c1c1;
  font-weight: bold;
  font-family: proxima-nova, sans-serif;
  font-weight: bold;
  font-size: 16px;
}

.FormModalsConnected p {
  margin: 0;
  font-style: italic;
}

.footerLine {
  color: white;
  background-color: #000000;
  /* height: 5px; */
  padding: 0px 10px 30px 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  text-align: left;
}

.footerLine p {
  display: flex;
  width: 95%;
  color: white;
  opacity: 0.3;
  font-size: 13px;
  text-align: left;
  margin-top: 20px;
}

.linee {
  width: 95%;
  background-color: white;
  height: 1px;
  opacity: 0.2;
}

.FormModalsConnected h4 {
  margin: 0px;
  margin-top: 30px;
  font-size: 22px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.AboutsConFormModalsConnectednected h6 {
  margin: 0px;
  margin-top: 7px;
  opacity: 0.3;
  font-size: 15px;
  font-weight: 400;
}

.FormModalsConnected h4:hover {
  color: #ffd400;
  /* transition: color 0.3s ease; */
}

.ModalAboutParas {
  font-size: 13.5px !important;
  display: flex;
  flex-direction: column !important;
  gap: 2%;
  margin: 4px 0px 0px 5px;
}

.ModalAboutParas p:hover {
  color: #ffd400;
}

.ModalAboutParas p {
  /* font-weight: 600 !important; */
  color: #666666;
  opacity: 1;
  font-size: 13.5px;
  width: 50%;
}

.ModalTwoInputs {
  display: flex;
  gap: 16px;
}

.ModaltextArea {
  width: 90.5%;
  height: 192px;
  border: 1px solid #d6d6d6;
  resize: none;
  padding-left: 2%;
  padding-top: 5px;
  background-color: #231f1f;
  color: white;
  font-weight: bold;
  font-size: 16px;
  opacity: 0.7;
}
.formToButton {
  -webkit-tap-highlight-color: transparent !important;
}
.ModalMessage {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  justify-content: center;
  border: none;
}

/* end */

.FormModal {
  width: 800px;
  /* height: 1201.14px; */
  background-color: #231f1f;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  padding-bottom: 70px;
  position: relative;
}

.FormModal h3 {
  font-size: 40px;
  color: white;
  font-weight: normal;
}

.FormModal p {
  width: 130px;
  font-size: 18px;
}

.ModalTwoInputs {
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.FormModalsConnected {
  width: 90%;
}

.FormModal p {
  color: white;
}

.FormModalHeadPara {
  margin-left: 67px;
}

.inputNDrop {
  display: flex;
  gap: 2%;
  width: 93%;
}

.zipMain {
  width: 92.5%;
}

.ZIP {
  background-color: #231f1f;
  border: 1px solid #d6d6d6;
  outline: none;
  height: 35px;
  width: 47%;
  font-weight: bold;
  font-size: 16px;
  color: #c1c1c1;
}

@media screen and (max-width: 630px) {
  .FormModal h3 {
    font-size: 28px;
    color: white;
    font-weight: normal;
  }

  .FormModalHeadPara {
    margin-left: 0px;
  }

  .FormModalHeadPara {
    width: 84%;
    margin-left: 0px;
  }
}
