.floridaLastSellSecFourthMain{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    font-family: proxima-nova, sans-serif;
    /* height: 525px; */
    /* margin-top: -40px; */
    /* background: linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)),
    url("../images/floridaLast.png"); */
    background: url("../images/floridaLast.png");
    background-position: center center !important;
    background-repeat: no-repeat;
    font-family: proxima-nova, sans-serif;
    background-size: cover;
    background-color: aliceblue;
    padding: 115px 0px;
    width: 100%;
}
.parasFloridaBlack{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.floridaLastSellSecFourth {
    background-color: #000000;
    /* height: 433px; */
    padding: 72px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    width: 62%;
    margin: 0 auto !important;
  }
  
  .floridaLastSellSecondPara {
    width: 70%;
    justify-content: flex-start;
    align-items: center;
    color: white;
    font-weight: 400;
    font-size: 18px;
/* margin-bottom: 100px; */
  }
  .floridaLastYellow{
    margin: 20px 0px;
    height: 1px;
    background-color: #FFD400;
    width: 40%;
}
  .SellSecondRed {
    color: #ffd400;
    font-size: 31px;
    font-weight: 100;
    margin: 0px;
    width: 85%;
  }
  
.floridaLastSellSecFourth button {
    font-size: 18px;
    color: white;
    width: 100%;
    height: 50px;
    border: none;
    background-color: transparent;
    font-style: italic;
    cursor: pointer;
    font-family: EB Garamond, sans-serif !important;
    font-weight: 900;
    border: 1px solid #ffd400;
    padding: 0px 30px;
  }


  .floridaLastSellSecFourth a {
    width: 326.86px;
  }
  .floridaLastSellSecFourth a button:hover {
    /* border: 1px solid #ffd400; */
    background-color: #ffd400;
    color: #000000;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  @media screen and (max-width: 490px) {
    .floridaLastSellSecFourth {
      background-color: #000000;
      padding: 72px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      text-align: center;
      width: 90%;
    }
  
    .floridaLastSellSecondPara {
      width: 80%;
  
      justify-content: flex-start;
      align-items: center;
      color: white;
      font-weight: 400;
      font-size: 18px;
    }
  
    .SellSecondRed {
      color: #ffd400;
      font-size: 24px;
      /* padding: 0px 15px; */
      width: 85%;
    }
  }
  
  @media screen and (max-width: 750px) and (min-width: 500px) {
    .floridaLastSellSecFourth {
      background-color: #000000;
      padding: 72px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      text-align: center;
    }
  
    .floridaLastSellSecondPara {
      width: 80%;
  
      justify-content: flex-start;
      align-items: center;
      color: white;
      font-weight: 400;
      font-size: 21px;
    }
  
    .SellSecondRed {
      color: #ffd400;
    }
    .companyReviews img {
      width: 32% !important;
      object-fit: contain;
    }
  }
  
  @media screen and (max-width: 1000px) and (min-width: 750px) {
    .floridaLastSellSecFourth {
      background-color: #000000;
      padding: 72px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      text-align: center;
    }
  
    .floridaLastSellSecondPara {
      width: 80%;
  
      justify-content: flex-start;
      align-items: center;
      color: white;
      font-weight: 500;
      font-size: 21px;
    }
  
    .SellSecondRed {
      color: #ffd400;
    }
  }
  