.dateTime{
    display: flex;
    flex-direction: row;
}


@media screen and (max-width: 1270px) {
    .dateTime{
        display: flex;
        flex-direction: column;
    }
}
