.feedbackResponse{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    font-family: proxima-nova, sans-serif;
    height: 450px;
    margin-top: -40px;
}

.feedbackResponse h1{
    font-size: 68px;
    font-weight: 300;
    margin-bottom: 10px;
}

.feedbackResponse p{
    font-family: proxima-nova, sans-serif;
    display: block;
    max-width: 600px;
    text-align: center;
    font-size: 24px;
    margin: 0 auto 30px auto;
}


.feedbackResponse button {
    font-size: 18px;
    color: white;
    width: 100%;
    height: 50px;
    border: none;
    background-color: transparent;
    font-style: italic;
    cursor: pointer;
    font-family: EB Garamond, sans-serif !important;
    font-weight: 900;
    border: 1px solid #ffd400;
    -webkit-tap-highlight-color: transparent !important; 
  }


  .feedbackResponse a {
    width: 235px;
  }
  .feedbackResponse a button:hover {
    /* border: 1px solid #ffd400; */
    background-color: #ffd400;
    color: #000000;
    transition: background-color 0.3s ease, color 0.3s ease;
  }


  @media only  screen and (max-width: 640px) {

    .feedbackResponse h1{
        font-size: 31px;
        font-weight: 300;
        margin-bottom: 10px;
    }
    .feedbackResponse p{
        font-family: proxima-nova, sans-serif;
        display: block;
        max-width: 600px;
        text-align: center;
        padding: 5px 20px;
        font-size: 18px!important;
        margin: 0 auto 30px auto;
    }
    .feedbackResponse button {
        font-size: 16px;
        color: white;
        width: 100%;
        height: 50px;
        border: none;
        background-color: transparent;
        font-style: italic;
        cursor: pointer;
        font-family: EB Garamond, sans-serif !important;
        font-weight: 900;
        border: 1px solid #ffd400;
      }.feedbackResponse a {
    width: 235px;
    /* max-width: 240px; */
  }

  }