
.banners{
    background: linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)),
    url("../images/CMA-Background.webp");
  background-position: center center !important;
    height: 100vh;
    background-size: cover !important;
    width: 100%;
    position: fixed;
    top:0px;
    z-index: -1;
}
.overlayeds{
    margin-top: 30px;
}
