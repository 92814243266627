.secondSecSell {
    background-color: #000000;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  }
  
  .secondParaSell {
    width: 85%;
  
    justify-content: flex-start;
    align-items: center;
    color: white;
    font-weight: 400;
    font-size: 31px;
  }
  
  .secondRedSell {
    color: #ffd400;
    font-weight: 700;
  }
  
  @media screen and (max-width: 490px) {
    .secondSecSell {
      background-color: #000000;
      height: 250px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      text-align: center;
    }
  
    .secondParaSell {
      width: 80%;
  
      justify-content: flex-start;
      align-items: center;
      color: white;
      font-weight: 400;
      font-size: 18px !important;
    }
  
    .secondRedSell {
      color: #ffd400;
    }
  }
  
  @media screen and (max-width: 750px) and (min-width: 500px) {
    .secondSecSell {
      background-color: #000000;
      height: 250px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      text-align: center;
    }
  
    .secondParaSell {
      width: 80%;
  
      justify-content: flex-start;
      align-items: center;
      color: white;
      font-weight: 400;
      font-size: 24px;
    }
  
    .secondRedSell {
      color: #ffd400;
    }
    .companyReviews img {
      width: 32% !important;
      object-fit: contain;
    }
  }
  
  @media screen and (max-width: 1000px) and (min-width: 750px) {
    .secondSecSell {
      background-color: #000000;
      height: 250px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      text-align: center;
    }
  
    .secondParaSell {
      width: 80%;
  
      justify-content: flex-start;
      align-items: center;
      color: white;
      font-weight: 400;
      font-size: 24px;
    }
  
    .secondRedSell {
      color: #ffd400;
    }
  }
  
  @media screen and (max-width: 640px) {
    .secondParaSell {
        width: 80%;
    
        justify-content: flex-start;
        align-items: center;
        color: white;
        font-weight: 400;
        font-size: 24px !important;
      }
      .secondSecSell {
        background-color: #000000;
        height: 186px !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        text-align: center;
      }
}