.loader {
  border: 2px solid #ffffff;
  border-top: 3px solid #929699;
  border-radius: 50%;
  width: 15px;
  height: 15px;

  animation: spin 2s linear infinite;
  margin: 10px auto 0px auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  