.fifthSec {
    padding-top: 50px;
    background-color: #f3f3f3;
    /* height: 650px; */
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
  }
  
  .ThirdParaFifth {
    font-size: 30px !important;
    color: black !important;
    margin-top: 65px;
    font-weight: bold;
    margin-bottom: 0px;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .fifthSec h1 {
    font-size: 25px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  
  .ans {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 7px;
    line-height: 1.6;
    color: black;
  }
  
  .quesAns {
    width: 63%;
    color: black;
  }
  
  .quesAns h1 {
    /* color: #231F1F; */
    /* opacity: 0.8; */
    font-weight: 500;
    color: black;
  }
  .fifthSec p {
    font-size: 18px;
    color: black;
    /* font-weight: bold; */
    /* opacity: 0.9; */
  }
  
  .italic {
    margin-bottom: 90px;
  }
  @media screen and (max-width: 470px) {
    .fifthSec {
      padding-top: 50px;
      background-color: #f3f3f3;
      /* height: 650px; */
      display: flex;
      /* justify-content: center; */
      flex-direction: column;
      align-items: center;
      padding-bottom: 80px;
  
    }
  
    .ThirdParaFifth {
      width: 87% !important;
      font-size: 23px !important;
      color: #FFD400;
      margin-top: 65px;
      font-weight: 400;
      margin-bottom: 0px;
      text-align: center;
      margin-bottom: 10px;
    }
  
    .fifthSec h1 {
      font-size: 25px;
      font-weight: 400;
      margin-top: 10px;
      margin-bottom: 0px;
    }
  
    .ans {
      font-size: 18px;
      margin-top: 10px;
      margin-bottom: 7px;
      line-height: 1.6;
    }
  
    .quesAns {
      width: 90% !important;
      /* opacity: 0.9; */
    }
  
    .quesAns h1 {
      color: black;
      font-size: 18px !important;
      font-weight: 600;
    }
    .italic {
      margin-bottom: 0px;
    }
  }
  
  @media screen and (max-width: 750px) and (min-width: 630px) {
    .ThirdParaFifth {
      width: 74%;
      text-align: center;
      font-size: 31px !important;
      margin-bottom: 15px;
    }
  }
  
  @media screen and (max-width: 630px) and (min-width: 470px) {
    .ThirdParaFifth {
      width: 74%;
      text-align: center;
      font-size: 24px !important;
      margin-bottom: 15px;
    }
    .quesAns {
      width: 75%;
    }
  }
  
  @media screen and (max-width: 1000px) and (min-width: 750px) {
    .ThirdParaFifth {
      width: 72%;
      text-align: center;
      font-size: 30px !important;
      margin-bottom: 15px;
    }
    .quesAns {
      width: 75%;
    }
  }
  
  @media screen and (max-width: 1150px) and (min-width: 1020px) {
    .ThirdParaFifth {
      width: 70%;
      text-align: center;
      font-size: 24px !important;
      margin-bottom: 15px;
    }
    .quesAns {
      width: 64%;
      color: black;
    }
  }
  