.aboutBanner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    font-family: proxima-nova, sans-serif;
    /* height: 525px; */
    margin-top: -40px;
    background: linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)),
    url("../images/AboutUsBanner.jpg");
    background-position: center center !important;
    background-repeat: no-repeat;
    font-family: proxima-nova, sans-serif;
    background-size: cover;
    background-color: aliceblue;
    padding: 115px 0px;
}

.aboutBanner h1{
    font-size: 68px;
    font-weight: 200!important;
    margin-bottom: 10px;
    margin-top: 25px;
    width: 65%;
    font-family: proxima-nova, sans-serif;
}

.aboutBanner p{
    font-family: proxima-nova, sans-serif;
    display: block;
    max-width: 600px;
    text-align: center;
    font-size: 24px;
    line-height: 1.6;
    font-weight: bold;
    margin: 0 auto 30px auto;
    font-family: proxima-nova, sans-serif;
}


.aboutBanner button {
    font-size: 18px;
    color: white;
    width: 100%;
    height: 50px;
    border: none;
    background-color: transparent;
    font-style: italic;
    cursor: pointer;
    font-family: EB Garamond, sans-serif !important;
    font-weight: 900;
    border: 1px solid #ffd400;
    -webkit-tap-highlight-color: transparent !important; 
  }


  .aboutBanner a {
    width: 160px;
  }
  .aboutBanner a button:hover {
    /* border: 1px solid #ffd400; */
    background-color: #ffd400;
    color: #000000;
    transition: background-color 0.3s ease, color 0.3s ease;
  }


  @media only  screen and (max-width: 650px) {

    .aboutBanner h1{
        font-size: 34px !important;
        font-weight: 300;
        margin-bottom: 10px;
        /* margin-top: 150px; */
        width: 266px !important;
    }
    .aboutBanner p{
        font-family: proxima-nova, sans-serif;
        display: block;
        max-width: 600px;
        text-align: center;
        padding: 5px 20px;
        font-size: 24px!important;
        margin: 0 auto 30px auto;
        width: 88%;
    }
    .aboutBanner button {
        font-size: 16px;
        color: white;
        width: 100%;
        height: 50px;
        border: none;
        background-color: transparent;
        font-style: italic;
        cursor: pointer;
        font-family: EB Garamond, sans-serif !important;
        font-weight: 900;
        border: 1px solid #ffd400;
      }.aboutBanner a {
    width: 160px;
  }

  }


  @media only  screen and (max-width: 1023px) {
  .aboutBanner h1{
    font-size: 68px;
    font-weight: 300;
    margin-bottom: 10px;
    /* margin-top: 150px; */
    width: 85%;
}
.aboutBanner{
padding: 85px 0px;
}  
}


  @media only  screen and (max-width: 913px) {
    .aboutBanner h1{
      font-size: 68px;
      font-weight: 300;
      margin-bottom: 10px;
      /* margin-top: 150px; */
      width: 68%;
  }
    }
  

    @media only  screen and (max-width: 650px) {
        .aboutBanner h1{
          margin-top: 70px;
            font-size: 34px !important;
            font-weight: 300;
            margin-bottom: 10px;
            /* margin-top: 150px; */
            width: 266px !important;
        }
        .aboutBanner p{
            font-family: proxima-nova, sans-serif;
            display: block;
            max-width: 600px;
            text-align: center;
            padding: 5px 20px;
            font-size: 18px!important;
            margin: 0 auto 30px auto;
            width: 88%;
        }
    }
      
        /* @media only  screen and (max-width: 810px) and (max-width: 650px) {
          .feedbackResponseBannnerTwo h1{
            margin-bottom: 10px;
            width: 70% !important;
        } */
          
        
  