.sellSecFourth {
    background-color: #000000;
    height: 433px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    width: 95%;
    margin: 0 auto !important;
  }
  
  .sellSecondPara {
    width: 60%;
    justify-content: flex-start;
    align-items: center;
    color: white;
    font-weight: 600;
    font-size: 18px;
margin-bottom: 100px;
  }
  
  .SellSecondRed {
    color: #ffd400;
    font-size: 31px;
    font-weight: 100;
    margin: 0px;
  }
  
.sellSecFourth button {
    font-size: 18px;
    color: white;
    width: 100%;
    height: 50px;
    border: none;
    background-color: transparent;
    font-style: italic;
    cursor: pointer;
    font-family: EB Garamond, sans-serif !important;
    font-weight: 900;
    border: 1px solid #ffd400;
    padding: 0px 30px;
  }


  .sellSecFourth a {
    width: 326.86px;
  }
  .sellSecFourth a button:hover {
    /* border: 1px solid #ffd400; */
    background-color: #ffd400;
    color: #000000;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  @media screen and (max-width: 490px) {
    .sellSecFourth {
      background-color: #000000;
      height: 453px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      text-align: center;
    }
  
    .sellSecondPara {
      width: 80%;
  
      justify-content: flex-start;
      align-items: center;
      color: white;
      font-weight: 400;
      font-size: 24px;
    }
  
    .SellSecondRed {
      color: #ffd400;
    }
  }
  
  @media screen and (max-width: 750px) and (min-width: 500px) {
    .sellSecFourth {
      background-color: #000000;
      height: 499.36px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      text-align: center;
    }
  
    .sellSecondPara {
      width: 80%;
  
      justify-content: flex-start;
      align-items: center;
      color: white;
      font-weight: 400;
      font-size: 24px;
    }
  
    .SellSecondRed {
      color: #ffd400;
    }
    .companyReviews img {
      width: 32% !important;
      object-fit: contain;
    }
  }
  
  @media screen and (max-width: 1000px) and (min-width: 750px) {
    .sellSecFourth {
      background-color: #000000;
      height: 470px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      text-align: center;
    }
  
    .sellSecondPara {
      width: 80%;
  
      justify-content: flex-start;
      align-items: center;
      color: white;
      font-weight: 500;
      font-size: 24px;
    }
  
    .SellSecondRed {
      color: #ffd400;
    }
  }
  