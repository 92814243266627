.AboutJoinTeamMain{
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
font-family: proxima-nova, sans-serif;
padding: 72px 0px;
}
.AboutJoinTeamMainHeading p{
    font-size: 31px;
    font-weight: bold;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 20px;
}

.AboutJoinTeamMainPara{
    width: 63%;
}
.AboutJoinTeamMainPara p{
   font-size: 18px;
}
.vs{
    width: 60%;
    display: flex;
    padding-top: 60px;
    line-height: 1.5;
    padding-bottom: 60px;
}
.vsleft{
    width: 50%;
    /* border-right: 0.2px solid black; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vsleft p{
    width: 80%;
    color: #231F1F;
    font-size: 18px;
    margin-top: 0px;
}

.vsright{
    width: 50%;
    border-left: 0.2px solid #666666;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.vsright p{
    width: 80%;
   color: #231F1F;
   font-size: 18px;
   margin-top: 0px;
}
.vertLine{

}
.vsLeftHead{
    color: #666666 !important;
    font-size: 25px !important;
    margin-top: 0px;
}



.AboutJoinTeamMain button {
    font-size: 18px;
    color: #000000;
    width: 100%;
    height: 50px;
    border: none;
    background-color: transparent;
    font-style: italic;
    cursor: pointer;
    font-family: EB Garamond, sans-serif !important;
    font-weight: 900;
    border: 1px solid #ffd400;
    padding: 0px 3px;
    padding: 0px 35px;
  }


  .AboutJoinTeamMain a {
    padding: 0px 35px;
    margin-top: 50px;

  }
  .AboutJoinTeamMain a button:hover {
    background-color: #ffd400;
    color: #000000;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  @media only  screen and (max-width: 1024px) {
    .AboutJoinTeamMainPara{
        width: 70%;
    }
    
  }

  @media only  screen and (max-width: 640px) {
    .AboutJoinTeamMainPara{
        width: 90%;
    }
    .vs{
        width: 70%;
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }
    .vsright{
        border-left: none;
        width: 100%;
    }
    .vsleft {
        width: 100%;
    }
    .vsLeftHead{
        font-size: 18px !important;
    }
    .AboutJoinTeamMainHeading p{
        font-size: 24px !important;
    }
    .vsright p{
        width: 100%;
    } 
    .vsleft p{
        width: 100%;
    }
    .AboutJoinTeamMain button {
        width: 80%;
    }
}
