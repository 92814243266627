.strategyMain {
  margin: 72px 0px 172px 0px;
}

.circular-gallery {
  margin-top: 72px !important;
  position: relative;
  width: 670px; /* Adjust the container's width as a percentage for responsiveness */
  margin-left: -50px;
  height: 0;
  padding-bottom: 670px; /* Set the height based on the width to maintain a square aspect ratio */
  margin: auto;
  background-color: #f3f3f3;
}
.strategyPara {
  font-size: 31px;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0px;
}
.center-image {
  position: absolute;
  top: 54%;
  left: 52%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}
.center-image img {
  width: 50px;
  object-fit: contain;
}
.outer-image {
  position: absolute;
  width: 60px; /* Adjust the image size as needed */
  height: 60px; /* Adjust the image size as needed */
  transform-origin: 50% 100%;
  z-index: 1;
}

.outer-image img {
  width: 47%;
  height: 100%;
  /* border-radius: 50%; */
}

.arrow {
  position: absolute;
  left: 50%;
  z-index: 0;
}
.CircleImageText {
  /* color: #ffd400; */
  font-size: 18px;
  width: 105px;
}
.textTextImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 4px;
  margin-left: -5px;
  width: 93px;
}
.onHoverBlackCircle {
  background: #7d7b7b;
  border-radius: 50%;
  height: 145px;
  width: 145px;
  margin-top: -23px;
  margin-left: -32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
}
.onHoverBlackCircleText {
  width: 80%;
  text-align: center;
  font-size: 16px;
}
/* Media query for responsiveness */
@media screen and (max-width: 450px) and (min-width: 300px) {
  .circular-gallery {
    margin-top: 72px !important;
    position: relative;
    width: 75%; /* Adjust the container's width as a percentage for responsiveness */
    margin-left: -50px;
    height: 0;
    padding-bottom: 75%; /* Set the height based on the width to maintain a square aspect ratio */
    margin: auto;
    background-color: #f3f3f3;
  }
}

@media screen and (max-width: 1024px) and (min-width: 630px) {
  .circular-gallery {
    margin-top: 72px !important;
    position: relative;
    width: 500px;
    margin-left: -50px;
    height: 0;
    padding-bottom: 500px;
    margin: auto;
    background-color: #f3f3f3;
  }
  .outer-image {
    position: absolute;
    width: 2rem;
    height: 2rem;
    transform-origin: 50% 100%;
    z-index: 1;
  }
  .CircleImageText {
    font-size: 18px;
    width: 85px;
  }
  .center-image img {
    width: 27px;
    height: 27px;
    object-fit: contain;
  }
  .onHoverBlackCircle {
    height: 110px;
    width: 110px;
  }
  .onHoverBlackCircleText {
    font-size: 12px;
  }

  .center-image {
    position: absolute;
    top: 52%;
    left: 51%;
    font-size: 12px;
  }
  .textTextImage img{
    height: 33px;
    width: 33px;
    object-fit: contain;
  }
  .textTextImage{
    margin-left: -20px;
  }
}

@media screen and (max-width: 630px) and (min-width: 0px) {
  .circular-gallery {
    margin-top: 72px !important;
    position: relative;
    width: 205px; /* Adjust the container's width as a percentage for responsiveness */
    margin-left: -50px;
    height: 0;
    padding-bottom: 205px; /* Set the height based on the width to maintain a square aspect ratio */
    margin: auto;
    background-color: #f3f3f3;
  }
  .outer-image {
    position: absolute;
    width: 13px;
    height: 13px;
    transform-origin: 50% 100%;
    z-index: 1;
  }
  .CircleImageText {
    font-size: 8px;
    width: 36px;
  }
  .center-image img {
    width: 13px;
    height: 13px;
    object-fit: contain;
  }
  .onHoverBlackCircle {
    height: 49px;
    width: 49px;
    margin-left: -23px;
    margin-top: -17px;
  }
  .onHoverBlackCircleText {
    font-size: 5px;
    font-weight: lighter;
  }
  .strategyMain {
    margin: 72px 0px 110px 0px;
  }
  .center-image {
    position: absolute;
    top: 51%;
    left: 47%;
    font-size: 8px;

  }
  .textTextImage {
    margin-left: -16px;
    margin-top: -10px;
    width: 35px;
  }
}
