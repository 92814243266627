.AboutBlwStrategy {
  background-image: url("../images/house.png");
  background-repeat: no-repeat;
  background-position: center center !important;
  width: 100%;
  height: 450px;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  background-size: cover !important;
}

.AboutBlackCard {
  width: 75%;
  background-color: #000000;
  /* height: 204px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  gap: 30px;
  text-align: center;
  padding: 26px;

}


.AboutBlackCard h3 {
  font-size: 40px;
  font-weight: 400;
  margin: 0px;
}

.italic {
  font-style: italic;
}

.boldWhite {
  font-weight: bolder;
}

@media only screen and (max-width: 1023px) {
  .AboutBlwStrategy {
    padding: 72px 0px;
  }

}

@media screen and (max-width: 499px) {
  .AboutBlwStrategy {
    background-image: url("../images/house.png");
    background-position: center center !important;
    background-repeat: no-repeat;
    width: 100%;
    height: 390px !important;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    background-size: cover !important;
  }

  .AboutBlackCard {
    width: 75% !important;
    background-color: #000000;
    /* height: 190px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    gap: 30px;
    padding: 0px 40px !important;
  }

  .AboutBlackCard h3 {
    font-size: 28px !important;
    font-weight: 400;
    margin: 0px;
    text-align: center;
    width: 80%;
  }
}

@media screen and (max-width: 550px) and (min-width: 470px) {
  .AboutBlackCard {
    width: 75%;
    background-color: #000000;
    /* height: 200px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    gap: 30px;
  }

  .quesAns {
    width: 80%;
  }

  .AboutBlackCard h3 {
    font-size: 24px;
    font-weight: 400;
    margin: 0px;
  }
}

@media screen and (max-width: 640px) {
  .AboutBlackCard h3 {
    font-size: 28px;
    font-weight: 400;
    margin: 0px;
  }

  .AboutBlackCard {
    width: 80% !important;
    padding: 26px !important;
  }

  .AboutBlackCard h3 {
    width: 100% !important;
  }
}


@media screen and (max-width: 750px) and (min-width: 550px) {
  .AboutBlackCard h3 {
    font-size: 40px;
    font-weight: 400;
    margin: 0px;
  }

  .AboutBlackCard {
    width: 65%;
    background-color: #000000;
    /* height: 204px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    gap: 30px;
    text-align: center;
    padding: 26px;
  }

  .quesAns {
    width: 80%;
  }
}

@media screen and (max-width: 1000px) and (min-width: 750px) {
  .AboutBlackCard {
    width: 65%;
    background-color: #000000;
    /* height: 250px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    gap: 30px;
    text-align: center;
    padding: 36px;
  }
}

@media screen and (max-width: 1300px) and (min-width: 1020px) {
  .AboutBlackCard {
    width: 75%;
    background-color: #000000;
    /* height: 204px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    gap: 30px;
    text-align: center;
    padding: 26px;
  }
}

@media screen and (min-width: 1300px) {

  .AboutBlackCard {
    width: 75%;
    background-color: #000000;
    /* height: 204px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    gap: 30px;
    text-align: center;
    padding: 26px;
  }
}


@media screen and (max-width: 420px) and (min-width: 340px) {
  .AboutBlackCard {
    width: 75%;
    background-color: #000000;
    /* height: 225px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    gap: 30px;
  }

  .AboutBlackCard button {
    font-size: 18px;
    color: black;
    width: 202px;
    /* height: 78px; */
    border: none;
    background-color: #ffd400;
    font-style: italic;
    cursor: pointer;
    font-family: EB Garamond, sans-serif !important;
    font-weight: 900;
    padding: 0px 45px;
  }
}