.fourthSec2 {
  background-image: url("../images/room.webp");
  background-position: center center !important;
  background-repeat: no-repeat;
  width: 100%;
  /* background-size: 100%; */
  height: 450px;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  background-size: cover !important;
}

.redCard2 {
  width: 45%;
  background-color: #000000;
  /* height: 190px; */
  padding: 3.5% 0%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  gap: 30px;
  text-align: center;
}

.redCard2 button {
  font-size: 18px;
  color: black;
  width: max-content;
  height: 50px;
  border: none;
  background-color: #ffd400;
  font-style: italic;
  cursor: pointer;
  font-family: EB Garamond, sans-serif !important;
  font-weight: 900;
  padding: 0px 45px;
}
.redCard2 a {
  width: 75%;
}

.redCard2 h3 {
  font-size: 31px;
  font-weight: 400;
  margin: 0px;
  width: 80%;
}
.redCard2 a button:hover {
  background-color: white;
  color: #000000;
  transition: background-color 0.3s ease, color 0.3s ease;
}

@media screen and (max-width: 499px) {
  .fourthSec2 {
    background-image: url("../images/room.webp");
    background-position: center center !important;
    background-repeat: no-repeat;
    width: 100%;
    /* background-size: 100%; */
    height: 400px !important;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    background-size: cover;
  }

  .redCard2 {
    width: 75% !important;
    background-color: #000000;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    gap: 30px;
    padding: 25px 0px !important;
  }

  .redCard2 button {
    font-size: 18px;
    color: black;
    width: 100% !important;
    /* width: 55%!important; */
    height: 50px;
    border: none;
    background-color: #ffd400;
  }
  .redCard2 a {
    width: 60% !important;
  }

  .redCard2 h3 {
    font-size: 25px !important;
    font-weight: 400;
    margin: 0px;
  }
}

@media screen and (max-width: 550px) and (min-width: 470px) {
  .redCard2 {
    width: 75%;
    background-color: #000000;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    gap: 30px;
  }

  .quesAns {
    width: 80%;
  }

  .redCard2 h3 {
    font-size: 24px;
    font-weight: 400;
    margin: 0px;
  }
}

@media screen and (max-width: 750px) and (min-width: 550px) {
  .redCard2 {
    width: 61%;
    background-color: #000000;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    gap: 30px;
    text-align: center;
  }
  .redCard2 h3 {
    font-size: 24px;
    font-weight: 400;
    margin: 0px;
  }
}

@media screen and (max-width: 1020px) and (min-width: 640px) {
  .redCard2 {
    width: 57%;
    background-color: #000000;
    height: 178px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    gap: 30px;
    text-align: center;
  }
  .redCard2 h3 {
    font-size: 31px;
    font-weight: 400;
    margin: 0px;
  }
}

@media screen and (max-width: 1300px) and (min-width: 1020px) {
  .redCard2 {
    width: 46%;
    background-color: #000000;
    height: 174px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    gap: 30px;
    text-align: center;
  }
}
@media screen and (min-width: 1300px) {
  .redCard2 {
    width: 40%;
    background-color: #000000;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    gap: 30px;
    text-align: center;
  }
}
