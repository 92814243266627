.fourthSec {
  /* background-image: url("../images/house.png"); */
  background-repeat: no-repeat;
  background-position: center center !important;
  width: 100%;
  /* background-size: 100%; */
  height: 450px;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  background-size: cover;
}

.redCard {
  width: 45%;
  background-color: #000000;
   padding: 30px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  gap: 30px;
  text-align: center;
}

.redCard button {
  font-size: 18px;
  color: black;
  width: max-content;
  height: 50px;
  border: none;
  background-color: #ffd400;
  font-style: italic;
  cursor: pointer;
  font-family: EB Garamond, sans-serif !important;
  font-weight: 900;
  padding: 0px 45px;
}
.redCard a {
  display: flex;
  justify-content: center;
  /* font-style: none; */
  text-decoration: none;
  width: 75%;
}
.redCard a button:hover {
  background-color: white;
  color: #000000;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.redCard h3 {
  font-size: 31px;
  font-weight: 400;
  margin: 0px;
  width: 80%;
}
.italic {
  font-style: italic;
}

@media screen and (max-width: 499px) {
  .fourthSec {
    /* background-image: url("../images/house.png"); */
    background-position: center center !important;
    background-repeat: no-repeat;
    width: 100%;
    /* background-size: 100%; */
    height: 340px !important;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    background-size: cover;
  }

  .redCard {
    width: 75% !important;
    background-color: #000000;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    gap: 30px;
  }

  .redCard button {
    font-size: 18px;
    color: black;
    width: 239.66px;
    /* width: 55%!important; */
    height: 50px;
    border: none;
    background-color: #ffd400;
    font-style: italic;
    cursor: pointer;
  }
  .redCard a {
    width: 239.66px;
  }
  .redCard h3 {
    font-size: 25px !important;
    font-weight: 400;
    margin: 0px;
    text-align: center;
    width: 80%;
  }
}

@media screen and (max-width: 550px) and (min-width: 470px) {
  .redCard {
    width: 75%;
    background-color: #000000;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    gap: 30px;
  }

  .quesAns {
    width: 80%;
  }

  .redCard h3 {
    font-size: 24px;
    font-weight: 400;
    margin: 0px;
  }
}

@media screen and (max-width: 750px) and (min-width: 550px) {
  .redCard h3 {
    font-size: 25px;
    font-weight: 400;
    margin: 0px;
  }
  .redCard {
    width: 66%;
    background-color: #000000;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    gap: 30px;
  }

  .quesAns {
    width: 80%;
  }
}

@media screen and (max-width: 1000px) and (min-width: 750px) {
  .redCard {
    width: 62%;
    background-color: black;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    gap: 30px;
  }
}

@media screen and (max-width: 1300px) and (min-width: 1020px) {
  .redCard {
    width: 46%;
    background-color: #000000;
    /* height: 190px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    gap: 30px;
    text-align: center;
    padding: 30px 0px;
  }
}
@media screen and (min-width: 1300px) {
  .redCard {
    width: 40%;
    background-color: #000000;
    height: 185px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    gap: 30px;
    text-align: center;
  }
}

@media screen and (max-width: 420px) and (min-width: 340px) {
  .redCard {
    width: 75%;
    background-color: #000000;
    height: 225px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    gap: 30px;
  }
  .redCard button {
    font-size: 18px;
    color: black;
    width: 202px;
    /* height: 78px; */
    border: none;
    background-color: #ffd400;
    font-style: italic;
    cursor: pointer;
    font-family: EB Garamond, sans-serif !important;
    font-weight: 900;
    padding: 0px 45px;
  }
}
