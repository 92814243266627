.ContactBanner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    font-family: proxima-nova, sans-serif;
    /* height: 525px; */
    margin-top: -40px;
    background: linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)),
    url("../images/contactBanner.jpg");
    background-position: center center !important;
    background-repeat: no-repeat;
    padding: 144px 0px 36px;
    background-size: cover !important;
}

.ContactBanner h1{
    font-size: 68px;
    font-weight: 200!important;
    margin-bottom: 10px;
    margin-top: 25px;
    width: 65%;
}

.ContactBanner p{
    font-family: proxima-nova, sans-serif;
    display: block;
    max-width: 600px;
    text-align: center;
    font-size: 24px;
    margin: 0 auto 30px auto;
}


.ContactBanner button {
    font-size: 18px;
    color: white;
    width: 100%;
    height: 50px;
    border: none;
    background-color: transparent;
    font-style: italic;
    cursor: pointer;
    font-family: EB Garamond, sans-serif !important;
    font-weight: 900;
    border: 1px solid #ffd400;
    -webkit-tap-highlight-color: transparent !important; 
  }


  .ContactBanner a {
    width: 160px;
  }
  .ContactBanner a button:hover {
    /* border: 1px solid #ffd400; */
    background-color: #ffd400;
    color: #000000;
    transition: background-color 0.3s ease, color 0.3s ease;
  }


  @media only  screen and (max-width: 650px) {

    .ContactBanner h1{

        font-size: 34px !important;
        font-weight: 300;
        margin-bottom: 10px;
        /* margin-top: 150px; */
        width: 266px !important;
    }
    .ContactBanner p{
        font-family: proxima-nova, sans-serif;
        display: block;
        max-width: 600px;
        text-align: center;
        padding: 5px 20px;
        font-size: 24px!important;
        margin: 0 auto 30px auto;
        width: 88%;
    }
    .ContactBanner button {
        font-size: 16px;
        color: white;
        width: 100%;
        height: 50px;
        border: none;
        background-color: transparent;
        font-style: italic;
        cursor: pointer;
        font-family: EB Garamond, sans-serif !important;
        font-weight: 900;
        border: 1px solid #ffd400;
      }
    .ContactBanner a {
    width: 160px;
    /* max-width: 240px; */
  }

  }


  @media only  screen and (max-width: 1023px) {
  .ContactBanner h1{
    font-size: 68px;
    font-weight: 300;
    margin-bottom: 10px;
    /* margin-top: 150px; */
    width: 85%;
}
  }

  @media only  screen and (min-width: 1500px) {
  .ContactBanner{
    padding: 194px 0px 96px;
}
  }
  @media only  screen and (max-width: 913px) {
    .ContactBanner h1{
      font-size: 68px;
      font-weight: 300;
      margin-bottom: 10px;
      /* margin-top: 150px; */
      width: 68%;
  }
  .ContactBanner{
    padding: 144px 0px 54px;
        }
    }
  

    @media only  screen and (max-width: 650px) {
        .ContactBanner{
    padding: 108px 0px 36px;
        }
        
        .ContactBanner h1{
            font-size: 34px !important;
            font-weight: 300;
            margin-bottom: 10px;
            /* margin-top: 150px; */
            width: 80% !important;
            margin-top: 24px;
        }
        .ContactBanner p{
            font-family: proxima-nova, sans-serif;
            display: block;
            max-width: 600px;
            text-align: center;
            padding: 5px 20px;
            font-size: 18px!important;
            margin: 0 auto 30px auto;
            width: 88%;
        }
    }

        
  