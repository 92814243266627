.ControlDots {
  display: flex;
  justify-content: baseline;
  align-items: center;
  align-content: center;
  gap: 4px;
  z-index: 0;
  background-color: transparent !important;
  /* width: 20px; */
  bottom: 0px !important;
  display: flex;
  align-items: flex-end;
  width: 100%;
  /* width: 180px!important; */
  /* margin-left: 10px !important; */
  /* margin-bottom: 15px !important; */

  height: 50px;
}
.ControlDot {
  background-color: black;
  /* margin-top: 70px !important; */
  height: 4px;
  width: 50px;
  margin-left: 5px;
}
.card {
  width: 100% !important;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  background-color: #efefef;
  padding: 0px 0px;
  /* padding: 0px; */
}
.testimonial {
  width: 50%;
  display: flex;
  flex-direction: column;
  /* padding: 8rem 2rem 4rem; */
  justify-content: center;
  padding: 20px 30px;
  font-size: 18px;
  font-family: proxima-nova, sans-serif;
  align-items: center;
}
.testimonial p {
  line-height: 1.6;
  color: #231f1f;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
}
.carouselBtns {
  display: flex;
  gap: 3px;
}
.carouselButton {
  background-color: black;
  height: 5px;
  width: 20px;
}
.testimonialImages {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  /* height: -webkit-fill-available; */
  /* align-items: baseline; */
  /* min-height: 434px; */
}
.testimonialImages img {
  width: 93%;
  margin-right: -17px;
  /* margin-bottom: -90px; */
  /* height: 461px; */
  margin-top: -5px;
  vertical-align: top;
  object-fit: cover;
  height: -webkit-fill-available;
}
.testName {
  font-size: 0.8rem;
  margin-bottom: 0px;
  font-style: italic;
  opacity: 0.9;
}
.testomonialImage {
  width: 100px !important;
  height: 100px !important;
  object-fit: contain;
}

@media screen and (max-width: 1023px) {
  .testimonialImages {
    display: none;
  }
  .testimonial {
    width: 65%;
  }
  .testimonial p {
    text-align: center;
  }
  .ControlDots {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 4px;
    z-index: 0;
    background-color: transparent;
    /* width: 20px; */
    bottom: 0px !important;
    display: flex;
    align-items: flex-end;
    width: 100%;
    /* width: 180px!important; */

    margin-bottom: 15px !important;

    height: 50px;
  }
}

@media screen and (max-width: 640px) {
  .testimonialImages {
    display: none;
  }
  .testimonial {
    width: 90%;
  }
  .testimonial p {
    text-align: center;
  }
}
