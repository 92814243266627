.feedbackResponseBannnerTwo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    font-family: proxima-nova, sans-serif;
    height: 525px;
    margin-top: -40px;
    background: linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)),
    url("../images/guaranteedoffer.webp") !important;
    background-position: center center !important;
    background-repeat: no-repeat;
    
    background-size: cover !important;
}

.feedbackResponseBannnerTwo h1{
    font-size: 68px;
    font-weight: 200!important;
    margin-bottom: 10px;
    margin-top: 60px;
    width: 65%;
}

.feedbackResponseBannnerTwo button {
    font-size: 18px;
    color: white;
    width: 100%;
    height: 50px;
    border: none;
    background-color: transparent;
    font-style: italic;
    cursor: pointer;
    font-family: EB Garamond, sans-serif !important;
    font-weight: 900;
    border: 1px solid #ffd400;
    -webkit-tap-highlight-color: transparent !important; 
  }


  .feedbackResponseBannnerTwo a {
    width: 160px;
  }
  .feedbackResponseBannnerTwo a button:hover {
    background-color: #ffd400;
    color: #000000;
    transition: background-color 0.3s ease, color 0.3s ease;
  }


  @media only  screen and (max-width: 640px) {

    .feedbackResponseBannnerTwo h1{
        font-size: 31px;
        font-weight: 300;
        margin-bottom: 10px;
    }
    .feedbackResponseBannnerTwo p{
        font-family: proxima-nova, sans-serif;
        display: block;
        max-width: 600px;
        text-align: center;
        padding: 5px 20px;
        font-size: 18px!important;
        margin: 0 auto 30px auto;
    }
    .feedbackResponseBannnerTwo button {
        font-size: 16px;
        color: white;
        width: 100%;
        height: 50px;
        border: none;
        background-color: transparent;
        font-style: italic;
        cursor: pointer;
        font-family: EB Garamond, sans-serif !important;
        font-weight: 900;
        border: 1px solid #ffd400;
      }.feedbackResponseBannnerTwo a {
    width: 160px;

  }

  }


  @media only  screen and (max-width: 1023px) {
  .feedbackResponseBannnerTwo h1{
    font-size: 68px;
    font-weight: 300;
    margin-bottom: 10px;

    width: 85%;
}
  }


  @media only  screen and (max-width: 913px) {
    .feedbackResponseBannnerTwo h1{
      font-size: 68px;
      font-weight: 300;
      margin-bottom: 10px;
   
      width: 68%;
  }
    }
  

    @media only  screen and (max-width: 650px) {
        .feedbackResponseBannnerTwo h1{
          font-size: 34px;
          font-weight: 300;
          margin-bottom: 10px;
      
          width: 85%;
      }
        }
      
  
        
  