.secondSec {
  background-color: #000000;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.secondPara {
  width: 80%;

  justify-content: flex-start;
  align-items: center;
  color: white;
  font-weight: 400;
  font-size: 31px;
}

.secondRed {
  color: #ffd400;
  font-weight: 700;
}

.customBtn {
  font-size: 18px;
  color: black;
  width: max-content;
  height: 50px;
  border: none;
  background-color: #ffd400;
  font-style: italic;
  cursor: pointer;
  font-family: EB Garamond, sans-serif !important;
  font-weight: 900;
  padding: 0px 45px;
}

@media screen and (max-width: 490px) {
  .secondSec {
    background-color: #000000;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  }

  .secondPara {
    width: 80%;

    justify-content: flex-start;
    align-items: center;
    color: white;
    font-weight: 400;
    font-size: 24px;
  }

  .secondRed {
    color: #ffd400;
  }
}

@media screen and (max-width: 750px) and (min-width: 500px) {
  .secondSec {
    background-color: #000000;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  }

  .secondPara {
    width: 80%;

    justify-content: flex-start;
    align-items: center;
    color: white;
    font-weight: 400;
    font-size: 24px;
  }

  .secondRed {
    color: #ffd400;
  }
  .companyReviews img {
    width: 32% !important;
    object-fit: contain;
  }
}

@media screen and (max-width: 1000px) and (min-width: 750px) {
  .secondSec {
    background-color: #000000;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  }

  .secondPara {
    width: 80%;

    justify-content: flex-start;
    align-items: center;
    color: white;
    font-weight: 400;
    font-size: 24px;
  }

  .secondRed {
    color: #ffd400;
  }
}
