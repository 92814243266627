.AboutKnow {
    background-color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    text-align: center;
    padding: 72px 10%;
  }
  
  .knowSecondPara {
    justify-content: flex-start;
    align-items: center;
    color: white;
    font-weight: 600;
    font-size: 20.25px;
    display: flex;
    
  }
.parNline{
    padding: 0px 1.5%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
  .KnowSecondRed {
    color: #ffd400;
    font-size: 31px;
    font-weight: 100;
    margin: 0px;
  }
  
.AboutKnow button {
    font-size: 18px;
    color: white;
    width: 100%;
    height: 50px;
    border: none;
    background-color: transparent;
    font-style: italic;
    cursor: pointer;
    font-family: EB Garamond, sans-serif !important;
    font-weight: 900;
    border: 1px solid #ffd400;
    -webkit-tap-highlight-color: transparent !important; 
  }


  .AboutKnow a {
    width: 326.86px;
  }
  .AboutKnow a button:hover {
    background-color: #ffd400;
    color: #000000;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
.knowSecondParaMain{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.lineKnow{
    background-color: #ffd400;
    height: 1px;
    width: 40px;
}
.knowWhiteLine{
    background-color: #959494;
    height: 1px;
    width: 225px;
    margin-bottom: 72px;
    margin-top: 100px;
}
.knowPara{
    width: 58%;
    font-size: 22.5px;
    color: white;
    line-height: 1.5;
}
.AboutKnowMain{
  width: 100%;
  display: flex;
  justify-content: center;
}
@media only  screen and (min-width: 1190px) {
  .AboutKnow{
    width: 75% !important;
  }
}
@media only  screen and (max-width: 1024px) {
    .knowPara{
        width: 80%;
        font-size: 22.5px;
        color: white;
    }
    .knowSecondParaMain{
        width: 100%;
        display: flex;
        justify-content:flex-start;
        flex-wrap: wrap;

    }
}

@media only  screen and (max-width: 640px) {
    .knowSecondParaMain{
        display: flex;
        flex-direction: column;
    }
    .knowPara{
        display: flex;
        justify-content: center;
        width: 100%;
        font-size: 22.5px;
        color: white;
        line-height: 1.5;
    }
    .KnowSecondRed {
      font-size: 24px;
    }
    
}

@media only  screen and (max-width: 730px) {
    .AboutKnow {
        align-items: flex-start;
        padding: 72px 13%;
      }
    .knowSecondParaMain {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 60px;
    }
    .KnowSecondRed{
        margin-bottom: 36px;
    }
}