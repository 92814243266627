/* If using CSS */
@font-face {
  font-family: "proxima-nova";
  src: url("../src/fonts/proxima-nova.woff2") format("woff2");
}

.App {
  text-align: center;
  font-family: proxima-nova, sans-serif;
}
body {
  overflow-x: hidden;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
button{
  -webkit-tap-highlight-color: transparent !important; 
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.focused {
  /* border: 1px solid #D11242 !important; */
  border: 1px solid black !important;
  outline: none;
}
.focuseder {
  border: 1px solid #000000 !important;
}
