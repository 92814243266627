.AboutStoryWhite {
    background-color: white;
    /* height: 433px; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    color: black;
    padding: 18px 36px;
    align-items: flex-start;
    text-align: left;
  }
  
  .AboutSecondParaWhite {
    width: 85%;
    justify-content: flex-start;
    align-items: flex-start;
    color: black;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.6;

  }
  
  .AboutSecondRedWhite {
    color: black;
    font-size: 31px;
    font-weight: 100;
    margin: 0px;
  }
  
.AboutStoryWhite button {
    font-size: 18px;
    color: black;
    width: 100%;
    height: 50px;
    border: none;
    background-color: transparent;
    font-style: italic;
    cursor: pointer;
    font-family: EB Garamond, sans-serif !important;
    font-weight: 900;
    border: 1px solid #ffd400;
    margin-bottom: 18px;
  }

.AboutSecondParaWhite button{
  -webkit-tap-highlight-color: transparent !important; 
}
  .AboutStoryWhite a {
    width: 186.61px;
  }
  .AboutStoryWhite a button:hover {
    background-color: #ffd400;
    color: #000000;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  @media only  screen and (max-width: 1023px) {
    .AboutStoryWhite{
        background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: black;
    padding: 36px 25%;
    align-items: flex-start;
    text-align: left;
}
.AboutSecondParaWhite {
  width: 100%;
}
}


@media only  screen and (max-width: 630px) {
    .AboutStoryWhite{
        background-color: white;
    /* height: 433px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: black;
    padding: 36px 8%;
    align-items: flex-start;
    text-align: left;
}
.AboutSecondParaWhite {
  width: 100%;
}
}
